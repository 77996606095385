#investments {
    .bg-white {
        height: 100%;
        padding: 20px 25px
    }

    .title {
        text-transform: uppercase;
        font-family: "Exo2";
        font-size: 32px;
        font-weight: 300;
        color: $dark;
        margin-bottom: 10px;
    }

    .back {
        position: relative;
        padding-left: 30px;
        color: $default;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: '\e002';
            font-size: 15px;
            font-family: $dagobertFont;
            transform: rotate(180deg);

        }
    }

    .info-text {
        font-family: $navigationFont;
        font-size: 14px;
    }

    @import "investments/table";
}
