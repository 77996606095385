#milestones {
    position: relative;
    display: inline-block;
    width: 330px;
    border-left: 10px solid $blue;

    .milestoneItemContainer {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0px;
        }
        
        .milestoneItem {
            position: relative;
            text-align: left;
            color: $light;
            font-family: 'Exo2';
            font-weight: 400;
            padding-left: 100px;
            height: 30px;
            line-height: 30px;

            &::before {
                position: absolute;
                top: 16px;
                left: 20px;
                content: '';
                display: inline-block;
                width: 60px;
                height: 1px;
                background-color: $light;
            }
            
            &.founded {
                color: $dark;

                &::before {
                    background-color: $dark;
                }
            }
        }
    }
    
    .crowdFounding {
        position: absolute;
        top: 0x;
        left: 10px;
        width: 0px; 
        height: 30px;
        background-color: $yellow;
        color: $darkBlue;
        text-transform: uppercase;
        transform: rotate(90deg);
        line-height: 30px;
        transform-origin: 0 0;
    }
}