#contactform {
    background-color: $almostWhite;
    padding: 40px 0;

    .contactFormIcon {
        display: inline-block;
        width: 220px;
        height: 220px;
        margin-top: 60px;
        margin-right: 25%;

        img {
            width: 100%;
            height: auto;
        }
    }

    @include media-breakpoint-down(md) {
        .contactFormIcon {
            width: 120px;
            height: 120px;
            margin-top: 30px;
            margin-right: 0;

        }
    }

    @include media-breakpoint-down(sm) {
        .contactFormIcon {
            width: 60px;
            height: 60px;
            margin-top: 0px;
        }
    }
}