$titleFontSize: 24px;
$titleFontSizeMd: 22px;
$titleFontSizeXs: 18px;
$titleMarginTopXs: 30px;

$bodyFontSize: 15px;
$bodyFontSizeXs: 14px;
$bodyMarginTop: 30px;

$authorFontSize: 18px;
$authorFontSizeXs: 14px;
$authorMarginTop: 30px;

.greeting {
    @include section-spacing;

    .image {
        img {
            height: auto;
            max-width: 100%;

            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }
    }

    .title {
        color: $primary;
        font-family: $headerFont;
        font-size: $titleFontSize;
        text-transform: none;

        @include media-breakpoint-down(md) {
            font-size: $titleFontSizeMd;
        }

        @include media-breakpoint-down(xs) {
            font-size: $titleFontSizeXs;
            margin-top: $titleMarginTopXs;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .intro,
    .body {
        font-family: $mainFont;
        font-size: $bodyFontSize;
        font-weight: 300;
        line-height: 2;
        margin-top: $bodyMarginTop;

        @include media-breakpoint-down(xs) {
            font-size: $bodyFontSizeXs;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .author {
        color: $primary;
        font-family: $mainFont;
        font-size: $authorFontSize;
        font-weight: 600;
        margin-top: $authorMarginTop;
        text-align: right;

        @include media-breakpoint-down(xs) {
            font-size: $authorFontSizeXs;
        }
    }
}
