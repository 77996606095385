$topHeight: 550px;
$topPaddingTop: 80px;
$topPaddingTopLgMd: 70px;

$overlayBorderRadius: 16px;
$overlayPadding: 30px;
$overlayPaddingRight: 200px;

$titleFontSize: 40px;
$titleFontSizeLg: 32px;
$titleFontSizeSm: 28px;
$titleFontSizeXs: 26px;

$subtitleFontSizeFirstLine: 32px;
$subtitleFontSizeFirstLineLg: 28px;
$subtitleFontSizeFirstLineSm: 22px;
$subtitleFontSizeFirstLineXs: 22px;

$subtitleFontSize: 26px;
$subtitleFontSizeLg: 20px;
$subtitleFontSizeSm: 18px;
$subtitleFontSizeXs: 18px;
$subtitleMarginTop: 5px;

$linksMarginTop: 75px;
$linkMarginLeft: 75px;
$linkMarginTopSm: 35px;

$offersPadding: 35px;

$offerFontSize: 24px;
$offerFontSizeLg: 20px;
$offerFontSizeSm: 18px;
$offerFontSizeXs: 16px;
$offerMarginLeft: 25px;

$actionMarginTopMd: 35px;

.hero {
    .top {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        height: $topHeight;
        padding-top: $topPaddingTop;
        position: relative;

        @include media-breakpoint-down(lg) {
            padding-top: $topPaddingTopLgMd;
        }

        @include media-breakpoint-down(sm) {
            align-items: center;
            padding-top: 0;
        }

        &::after {
            background-color: transparentize($primary, 0.75);
            content: '';
            display: block;
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;
        }

        .video {
            background-color: $primary;
            height: 100%;
            left: 0;
            overflow: hidden;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;

            video {
                height: 100%;
                left: 0;
                object-fit: cover;
                object-position: bottom;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        > .container {
            position: relative;
            z-index: 3;
        }

        .overlay {
            background-color: transparentize($primary, 0.2);
            border-radius: $overlayBorderRadius;
            float: left;
            padding: $overlayPadding;
            padding-right: $overlayPaddingRight;
            position: relative;

            @include media-breakpoint-down(sm) {
                padding-right: $overlayPadding;
            }

            .title {
                @include media-breakpoint-down(lg) {
                    text-align: inherit;
                }

                @include media-breakpoint-down(sm) {
                    text-align: center;
                }

                h1 {
                    color: $white;
                    font-size: $titleFontSize;
                    font-weight: 700;
                    margin-bottom: 0;

                    @include media-breakpoint-down(lg) {
                        font-size: $titleFontSizeLg;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: $titleFontSizeSm;
                    }

                    @include media-breakpoint-down(xs) {
                        font-size: $titleFontSizeXs;
                    }
                }
            }

            .subtitle {
                color: $white;
                font-family: $mainFont;
                font-size: $subtitleFontSize;
                margin-top: $subtitleMarginTop;

                .subtitle-first-line {
                    font-size: $subtitleFontSizeFirstLine;
                }

                @include media-breakpoint-down(lg) {
                    font-size: $subtitleFontSizeLg;
                    text-align: inherit;

                    .subtitle-first-line {
                        font-size: $subtitleFontSizeFirstLineLg;
                    }
                }

                @include media-breakpoint-down(sm) {
                    font-size: $subtitleFontSizeSm;
                    text-align: center;

                    .subtitle-first-line {
                        font-size: $subtitleFontSizeFirstLineSm;
                    }
                }

                @include media-breakpoint-down(xs) {
                    font-size: $subtitleFontSizeXs;

                    .subtitle-first-line {
                        font-size: $subtitleFontSizeFirstLineXs;
                    }
                }
            }

            .link {
                background-image: url('../images/home/ecsp-seal.png');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                height: 140px;
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                width: 140px;
                z-index: 10;
            }
        }

        .links {
            display: flex;
            margin-top: $linksMarginTop;

            @include media-breakpoint-down(sm) {
                align-items: center;
                flex-direction: column;
            }

            .link {
                &:nth-child(2) {
                    margin-left: $linkMarginLeft;

                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                        margin-top: $linkMarginTopSm;
                    }
                }

                .btn {
                    text-transform: none;
                }

                .btn-primary {
                    border-color: transparent;
                }
            }
        }
    }

    .bottom {
        border-bottom: 2px solid $secondary;
        padding: $offersPadding 0;

        .offers {
            align-items: baseline;
            display: flex;
            justify-content: center;
            margin: 0 auto;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }

            .offer {
                align-items: baseline;
                display: flex;
                font-family: $mainFont;
                font-size: $offerFontSize;

                @include media-breakpoint-down(lg) {
                    font-size: $offerFontSizeLg;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $offerFontSizeSm;
                }

                @include media-breakpoint-down(xs) {
                    font-size: $offerFontSizeXs;
                }

                .label {
                    &::after {
                        content: '\00a0';
                    }
                }

                a {
                    color: $secondary;
                }

                [class^="icon-"] {
                    margin-left: $offerMarginLeft;
                }

                &:nth-child(2) {
                    margin-left: $offerMarginLeft;

                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                    }
                }
            }
        }

        .action {
            margin: 0 auto;

            @include media-breakpoint-down(md) {
                margin-top: $actionMarginTopMd;
            }

            .btn {
                text-transform: none;
            }
        }
    }
}
