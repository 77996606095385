#financialStructure {

    b {
        font-size: 18px;
    }

    .legendPart {
        .color {
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 10px;
        }
    
        .text {
            display: inline;
            margin-right: 20px;
        }
    }
    
    .donutChart {
        display: inline-block;
        position: relative;
        width: 220px;
        height: 220px;
    }

    .center {
        background: white;
        position: absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        width: 150px;
        height: 150px;
        margin: auto;
        border-radius: 50%;
    }

    .portionBlock {
        border-radius: 50%;
        clip: rect(0px, 220px, 220px, 110px);
        height: 100%;
        position: absolute;
        width: 100%;
    }
    
    .circle {
        border-radius: 50%;
        clip: rect(0px, 110px, 220px, 0px);
        height: 100%;
        position: absolute;
        width: 100%;
    }
}