&__sidebar {
    min-height: 100%;
    position: relative;

    &::before {
        background-color: $almostWhite;
        content: '';
        display: block;
        height: 100%;
        pointer-events: none;
        position: absolute;
        right: 0;
        width: 999999px;
        z-index: -1;
    }

    &__navigation {
        padding-bottom: 60px;
        padding-top: 30px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    &__navigation-item {
        align-items: center;
        border-bottom: 1px solid #ccc;
        color: $default;
        display: flex;
        font-family: $navigationFont;
        font-size: 18px;
        padding: 15px 25px;
        position: relative;
        transition: padding-left 200ms ease;

        &::before {
            background-color: $primary;
            bottom: -1px;
            content: '';
            display: block;
            left: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 0;
            transition: width 200ms ease;
        }

        &--active {
            background-color: $white;
            border-bottom-color: transparent;

            &::before {
                width: 5px;
            }
        }

        &:focus {
            outline: 0;
            padding-left: 30px;

            &::before {
                width: 5px;
            }
        }

        @media (hover: hover) {
            &:hover {
                color: $default;
                padding-left: 30px;
                text-decoration: none;

                &::before {
                    width: 5px;
                }
            }
        }

        i {
            color: #29bd07;
            margin-left: auto;
        }

        &--warning {
            i {
                color: $secondary;
                font-size: 50%; // The dot should be displayed smaller
                text-align: center;
                width: 18px; // Matches the regular icon sizes at this font size
            }
        }

        &--error {
            i {
                color: #ff1400;
            }
        }
    }

    &__legend {
        padding-bottom: 60px;
        padding-right: calc($grid-gutter-width / 2);

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

    &__legend-item {
        font-size: 13px;
        margin-top: 5px;
        padding-left: 20px;
        position: relative;

        &:first-child {
            margin-top: 0;
        }

        i {
            color: #29bd07;
            left: 0;
            position: absolute;
            top: 0;
        }

        &--warning {
            i {
                color: $secondary;
                height: 19.5px; // Matches the regular icon sizes at this font size
                font-size: 50%; // The dot should be displayed smaller
                line-height: 19.5px; // Matches the regular icon sizes at this font size
                text-align: center;
                width: 13px; // Matches the regular icon sizes at this font size
            }
        }

        &--error {
            i {
                color: #ff1400;
            }
        }
    }
}
