$titleFontSize: 52px;
$titleFontSizeMd: 46px;
$titleFontSizeSm: 40px;
$titleFontSizeXs: 24px;

$contentMarginTop: 75px;
$contentMarginTopSm: 50px;
$contentMarginTopXs: 30px;

$actionMarginTopSm: 30px;
$actionMarginTopXs: 20px;

.partners {
    @include section-spacing;

    &.use-bottom-spacing {
        @include section-spacing-bottom;
        padding-top: 0;
    }

    .title {
        text-align: center;

        h2 {
            color: $primary;
            font-size: $titleFontSize;
            font-weight: 700;
            margin: 0;
            text-transform: none;

            @include media-breakpoint-down(md) {
                font-size: $titleFontSizeMd;
            }

            @include media-breakpoint-down(sm) {
                font-size: $titleFontSizeSm;
            }

            @include media-breakpoint-down(xs) {
                font-size: $titleFontSizeXs;
            }
        }
    }

    .content {
        margin-top: $contentMarginTop;

        @include media-breakpoint-down(sm) {
            margin-top: $contentMarginTopSm;
        }

        @include media-breakpoint-down(xs) {
            margin-top: $contentMarginTopXs;
        }

        .items {
            .swiper-wrapper {
                align-items: center;

                .item {
                    img {
                        display: block;
                        height: auto;
                        width: 100%;
                    }
                }
            }
        }

        .action {
            text-align: center;

            @include media-breakpoint-down(sm) {
                margin-top: $actionMarginTopSm;
            }

            @include media-breakpoint-down(xs) {
                margin-top: $actionMarginTopXs;
            }
        }
    }

    &.no-title {
        .content {
            margin-top: 0;
        }
    }
}
