span.singleChild {
    display: block;
    position: relative;
    font-family: $headerFont;
    border: 1px solid $lighter;
    background-color: white;
    padding: 30px 20px 20px 20px;
    color: $dark;

    .badge {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 100%;
        height: 100px;
        line-height: 26px;
        font-family: $navigationFont;
        font-size: 15px;
        text-transform: uppercase;
        text-align: center;
        color: $almostWhite;
        border-radius: 0;
        font-weight: 400;

        &.yellow {
            background-color: $yellow;
        }

        &.blue {
            background-color: $blue;
        }
    }

    .titleLocation {
        height: 85px;
        overflow: hidden;

        .title {
            font-weight: 700;
            font-size: 30px;
            line-height: 1;
        }
    }

    .about {
        border-bottom: 2px solid $lighter;
        padding: 15px 0;

        font-size: 26px;
        line-height: 1;
        margin-bottom: 25px;

        .subText {
            color: #707070;
            font-size: 20px;
        }
    }

    .childDocuments {
        a.childDocument {
            display: inline-block;
            color: $lessDark;
            text-decoration: none;
            text-align: left;
            margin-right: 20px;

            &::before {
                content: '\e00b';
                font-family: $dagobertFont;
                font-size: 20px;
                margin-right: 5px;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    .countryInvestments {
        margin-top: 25px;
        border-top: 2px solid $lighter;
        padding-top: 15px;
        line-height: 1;
        color: black;

        table {
            width: 100%;

            tr {

                border: none !important;

                td {
                    height: 23px;

                    img {
                        height: 18px;
                    }

                    &:first-child {
                        width: 200px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .titleLocation {
            .title {
                font-size: 28px;
            }
        }

        .about {
            font-size: 20px;
            line-height: 1;

            .subText {
                color: $default;
                font-size: 14px;
            }
        }

        .countryInvestments {
            font-size: 14px;
            table {
    
                tr {
    
                    td {
    
                        &:first-child {
                            width: 180px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-only(sm) {
        .titleLocation {
            .title {
                font-size: 30px;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .about { 
            .subText {
                display: inline;
                margin-left: 20px;
            }
        }

        .countryInvestments {
            font-size: 12px;
            table {
    
                tr {
    
                    td {
    
                        &:first-child {
                            width: 150px;
                        }
                    }
                }
            }
        }
    }
}