#user {
    // elements
    @import "elements/menu";
    @import "elements/welcome";

    //pages
    @import "pages/dashboard";
    @import "pages/friendship";
    @import "pages/investments";
    @import "pages/messages";
    @import "pages/userdata";
    @import "pages/buyInvestment";
    @import "pages/shareholderDashboard";
    @import "pages/experienceData";
    @import "pages/affiliates";
}
