.description {
    table {
        width: 100%;

        p {
            margin: 0;
        }

        tr {
            td,
            th {
                border-top: 1px solid #ddd;
                padding: 5px;
                width: 50%;
            }

            &:nth-child(even) {
                td,
                th {
                    background-color: #f8f8f8;
                }
            }

            &.heading {
                td,
                th {
                    background-color: $secondary;
                    border-top: 0;
                    color: $white;
                    padding: 10px 5px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.mainDescription,
.detailDescription {
    &.use-primary {
        .description {
            table {
                tr {
                    &.heading {
                        td,
                        th {
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }
}
