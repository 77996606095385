#shareholder-dashboard {
    .bg-white {
        height: 100%;
        padding: 20px 25px
    }

    .title {
        text-transform: uppercase;
        font-family: "Exo2";
        font-size: 32px;
        font-weight: 300;
        color: $dark;
        margin-bottom: 30px;
    }

    dl {
        display: flex;
        flex-wrap: wrap;

        dt,
        dd {
            font-family: $navigationFont;
            margin: 0 0 1em;
            width: 50%;
        }

        dt {
            font-weight: 600;
        }
    }
}
