#subpage {
    @import "subpage/jumbo";
    max-width: 100%;
    overflow-x: hidden;

    p {
        padding: 0;
        margin: 0;
        margin-bottom: 0;
    }
    .nl_designer_row_table_col {
        padding: 0 15px !important;
    }
    .row_div {
        display: flex;

        a[href=''] {
            pointer-events: none;
        }
    }

    .image-gallery-container {
        .carousel {
            &.flickity-enabled {
                height: auto !important; // This fixes the non-responsive default behavior of carousel galleries
            }
        }
    }

    @import "subpage/info";
    @import "subpage/steps";
    @import "subpage/video";
    @import "subpage/texts";
    @import "subpage/faq";
    @import "subpage/glossary";
}
