#steps {
    padding-top: 100px;
    padding-bottom: 130px;
    color: $dark;

    .step {
        position: relative;
        padding-left: 200px;
        padding-right: 80px;

        .number {
            position: absolute;
            top: 5px;
            left: 60px;
            display: block;
            width: 70px;
            height: 70px;
            border: 1px $dark solid;
            background-color: white;
            font-family: $font-alt;
            font-weight: 300;
            font-size: 52px;
            line-height: 64px;
            text-align: center;
        }

        .icon {
            position: absolute;
            top: 5px;
            left: 60px;
            display: block;
            width: 70px;
            height: 70px;
            background: url('/images/icons/bullet-point.svg') top left no-repeat;
            background-size: contain;
        }

        h3, h2 {
            font-family: $font-alt;
            font-weight: 700;
            font-size: 30px;
            margin-bottom: 25px;
            line-height: 1;
        }

        .text {
            margin-bottom: 70px;
        }
    }

    @include media-breakpoint-down(lg) {
        padding-top: 80px;
        padding-bottom: 110px;
        .step {
            padding-left: 150px;
    
            .number {
                left: 0px;
            }

            .icon {
                left: 0px;
            }
    
            .text {
                margin-bottom: 40px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding-top: 60px;
        padding-bottom: 80px;
        .step {
            padding-left: 85px;
            padding-right: 0;

            .number {
                width: 55px;
                height: 55px;
                font-size: 40px;
                line-height: 50px;
            }

            .icon {
                width: 55px;
                height: 55px;
            }

            .title {
                font-size: 28px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 40px;
        padding-bottom: 70px;
        .step {
            padding-left: 65px;

            .number {
                width: 45px;
                height: 45px;
                font-size: 33px;
                line-height: 40px;
            }

            .icon {
                width: 45px;
                height: 45px;
            }

            .title {
                font-size: 24px;
            }
    
            .text {
                font-size: 14px;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .step {
            padding-left: 50px;

            h3, h2 {
                font-size: 18px;
            }

            .number {
                width: 35px;
                height: 35px;
                font-size: 26px;
                line-height: 30px;
            }

            .icon {
                width: 35px;
                height: 35px;
            }

            .title {
                font-size: 22px;
            }
    
            .text {
                font-size: 14px;
                text-align: left;
                margin-left: -50px;
            }
        }
    }
}