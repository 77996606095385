#legal-complaint-form {
    background-color: $almostWhite;
    padding: 40px 0;

    p {
        margin-top: 1rem !important;

        &:first-child {
            margin-top: 0 !important;
        }
    }
}
