$glossarySearchHeight: 48px;
$glossarySearchHeightPhone: 40px;
$glossaryElementMarginTop: 50px;
$glossaryElementMarginTopPhone: 30px;

.glossary {
    @include section-spacing-bottom;

    .description {
        color: $dark;
        font-family: $headerFont;
        font-size: 34px;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            font-size: 24px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 18px;
        }
    }

    .search {
        margin-top: $glossaryElementMarginTop;

        @include media-breakpoint-down(xs) {
            margin-top: $glossaryElementMarginTopPhone;
        }

        .info {
            font-family: $mainFont;
            font-size: 17px;
            line-height: calc($glossarySearchHeight / 2);

            @include media-breakpoint-down(sm) {
                line-height: inherit;
            }

            @include media-breakpoint-down(xs) {
                font-size: 14px;
            }
        }

        .input {
            @include media-breakpoint-down(sm) {
                margin-top: 15px;
            }

            input {
                border: 1px solid $secondary;
                color: $default;
                height: $glossarySearchHeight;
                font-family: $mainFont;
                font-size: 17px;
                line-height: $glossarySearchHeight - 2px;
                padding: 0 20px;
                width: 100%;

                &:focus {
                    outline: 0;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 14px;
                    height: $glossarySearchHeightPhone;
                    line-height: $glossarySearchHeightPhone - 2px;
                }
            }
        }

        .clear {
            @include media-breakpoint-down(sm) {
                margin-top: 15px;
            }

            button {
                @include input-reset;
                background-color: $gray;
                color: $white;
                display: block;
                font-family: $mainFont;
                font-size: 20px;
                height: $glossarySearchHeight;
                padding: 0 20px;
                text-transform: uppercase;
                width: 100%;

                @include media-breakpoint-down(sm) {
                    margin: 0 auto;
                    width: auto;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 16px;
                    height: $glossarySearchHeightPhone;
                }
            }
        }
    }

    .categories {
        margin-top: $glossaryElementMarginTop;

        @include media-breakpoint-down(xs) {
            margin-top: $glossaryElementMarginTopPhone;
        }

        > .row {
            @include custom-gutter(20px);
        }

        .category {
            height: 0;
            margin-top: 20px;
            padding-bottom: 100%;
            position: relative;
            height: 0;

            a,
            span {
                align-items: center;
                border: 1px solid $secondary;
                display: flex;
                font-family: $navigationFont;
                font-size: 30px;
                height: 100%;
                justify-content: center;
                left: 0;
                top: 0;
                position: absolute;
                width: 100%;

                @include media-breakpoint-down(lg) {
                    font-size: 26px;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 23px;
                }
            }

            a {
                color: primary;

                @media (hover: hover) {
                    &:hover {
                        background-color: $primary;
                        border-color: $primary;
                        color: $white;
                        text-decoration: none;
                    }
                }
            }

            span {
                color: $lighter;
            }
        }
    }

    .list {
        .category {
            margin-top: $glossaryElementMarginTop;

            .name {
                border-bottom: 1px solid $secondary;
                padding-bottom: 5px;

                h2 {
                    color: $primary;
                    font-size: 26px;
                    font-weight: 500;
                    margin: 0;

                    @include media-breakpoint-down(xs) {
                        font-size: 22px;
                    }
                }
            }

            .items {
                .item {
                    margin-top: 30px;

                    .title {
                        h3 {
                            color: $primary;
                            font-size: 24px;
                            margin: 0;
                            text-transform: none;

                            @include media-breakpoint-down(xs) {
                                font-size: 17px;
                            }
                        }
                    }

                    .content {
                        font-family: $mainFont;
                        font-size: 15px;

                        @include media-breakpoint-down(xs) {
                            font-size: 13px;
                        }

                        p {
                            margin-top: 15px;
                        }
                    }
                }
            }

            .back-to-top {
                display: flex;
                margin-top: 30px;

                a {
                    background-color: $lightest;
                    color: $default;
                    display: block;
                    font-family: $mainFont;
                    font-size: 13px;
                    margin-left: auto;
                    padding: 5px 15px;
                    text-transform: uppercase;

                    &::after {
                        content: '▲';
                        margin-left: 10px;
                    }

                    @media (hover: hover) {
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
