.language-selector {
    background-color: $lightest;
    height: 70px;
    position: fixed;
    right: 0;
    top: 360px;
    width: 70px;
    z-index: 4;

    @include media-breakpoint-down(lg) {
        top: 305px;
    }

    @include media-breakpoint-down(md) {
        top: 260px;
    }

    @include media-breakpoint-down(xs) {
        top: 185px;
        width: 55px;
        height: 55px;
    }

    &__current-language {
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 40%;
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        position: absolute;
        text-indent: -999999px;
        width: 100%;
    }

    &__options {
        background-color: $lightest;
        left: 0;
        position: absolute;
        top: 100%;
        width: 100%;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                button {
                    @include input-reset;

                    align-items: center;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: auto 40%;
                    display: flex;
                    height: 70px;
                    justify-content: center;
                    text-indent: -999999px;
                    width: 70px;

                    @include media-breakpoint-down(xs) {
                        width: 55px;
                        height: 55px;
                    }
                }
            }
        }
    }
}
