.ich-button {
    background-color: #fff;
    border: 2px solid #00E096;
    border-radius: 20px;
    color: #222B45;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    padding: 0 20px 0 55px;
    background-image: url('/images/ich/MASTER.svg');
    background-repeat: no-repeat;
    background-size: 22px;
    background-position-y: center;
    background-position-x: 15px;
    text-decoration: none;
    font-weight: 600;

    &:hover {
        text-decoration: none;
        border-color: #51F0B0;
    }
}


.ich-app--prefilled {
    margin-top: 40px !important;
    .dg-input-wrapper input[readonly],
    .dg-input-wrapper select[disabled] {
        cursor: not-allowed;
        background-color: #DADADA;
        background-image: url('/images/ich/MASTER.svg');
        background-repeat: no-repeat;
        background-size: 22px;
        background-position-y: center;
        background-position-x: 15px;
        padding: 0 30px 0 50px;
        opacity: 1;
    }

    .dg-input-wrapper input[disabled] {
        cursor: not-allowed;
    }

    .registration-field {
        margin-top: 15px !important;
    }
}
