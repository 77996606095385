#affiliates {
    .bg-white {
        height: 100%;
        padding: 20px 25px
    }
    
    table {
        width: 100%;
        font-family: $navigationFont;
        font-size: 14px;
        color: $lessDark;
        margin-top: 20px;

        tr {
            td {
                padding: 10px 5px;
                border-bottom: 1px $light solid;
                text-align: center;

                &:nth-child(1) {
                    text-align: left;
                }
            }
        }

        thead {
            tr {
                td {
                    font-size: 16px;
                    font-weight: 600;
                    vertical-align: bottom;
                }
            }
        }
    }
}