&__subtitle {
    align-items: end;
    border-bottom: 1px solid #ccc;
    display: flex;
    margin-top: 60px;
    padding-bottom: 5px;

    &__heading {
        color: $dark;
        font-family: $headerFont;
        font-size: 21px;
        line-height: 1;
        text-transform: uppercase;
    }

    &__action {
        line-height: 1;
        margin-left: auto;

        button {
            @include input-reset;

            color: $gray;
            font-size: 14px;

            i {
                margin-left: 5px;
            }
        }
    }
}
