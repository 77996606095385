.mainImage {
    img {
        margin-top: 80px;
        width: 100%;
        height: auto;
    }

    @include media-breakpoint-down(xs) {
        img {
            margin-top: 50px;
        }
    }
}