.knowledgableInvestorContainer {
    .headerContainer {
        position: relative;
        background: $yellow;
        height: 160px;
        color: $lessDark;
        text-transform: uppercase;
        font-family: $font-alt;
        font-size: 30px;
        padding-top: 25px;
        line-height: 1;

        .closeIcon {
            cursor: pointer;
            position: absolute;
            display: block;
            width: 35px;
            height: 35px;
            line-height: 1;
            font-size: 35px;
            right: 20px;
            top: 25px;
            z-index: 12;
        }

        .title {
            font-size: 64px;
        }
    }

    .bodyContainer {
        background: white;
        padding: 0 15px 15px 15px;

        .errorFrame {
            background-color: rgba($color: $red, $alpha: 0.3);
            border-bottom: 1px $red solid;
            color: $red;
            font-weight: bold;
            min-height: 40px;
            line-height: 40px;
        }

        .infoFrame {
            background-color: rgba($color: $green, $alpha: 0.3);
            border-bottom: 1px $green solid;
            color: $green;
            font-weight: bold;
            min-height: 40px;
            padding: 10px;
        }

        .success {
            font-size: 36px;
            font-family: $navigationFont;
            color: $lessDark;
        }

        .projectName {
            margin: 20px 0;
            font-size: 32px;
            font-family: $font-alt;
            text-transform: uppercase;
            font-weight: 300;
        }

        table {
            width: 100%;
            font-family: $navigationFont;

            tr {
                border-bottom: 1px $lighter solid;

                &:first-child {
                    border-top: 1px $lighter solid;
                }

                td {
                    height: 55px;
                    padding-left: 15px;

                    &:first-child {
                        font-weight: 600;
                    }
                }
            }
        }

        .subtitle {
            font-family: $navigationFont;
            font-size: 24px;
            margin-bottom: 15px;
            color: black;
        }

        .form-control {
            &.error {
                border-color: $yellow;
                color: $red;
            }
        }

        .hint {
            max-height: 0;
            overflow: hidden;
            font-size: 15px;
            color: $lessDark;
            background-color: $almostWhite;
            padding: 0;
            margin-bottom: 0px;
            transition: all 0.75s linear;

            b {
                font-size: 17px;
            }

            &.error {
                overflow: unset;
                max-height: 100%;
                padding: 15px;
                position: relative;
                border: 1px $yellow solid;
                background-color: white;

                &::before {
                    position: absolute;
                    display: block;
                    content: "";
                    top: -40px;
                    left: 0;
                    width: 36px;
                    height: 30px;
                    background: url("/images/icons/alert.svg") top left no-repeat;
                }
            }

            &.show {
                max-height: 100%;
                padding: 15px;
                margin-bottom: 50px;
            }
        }

        .form-check-label {
            margin-left: 20px;

            i {
                margin-left: 15px;
                color: $blue;
                font-size: 20px;
                line-height: 1;
            }
        }

        .info {
            position: relative;
            border: 1px $blue solid;
            padding-left: 75px;
            padding: 20px 75px;
            font-size: 15px;

            &::before {
                position: absolute;
                display: block;
                content: '\e017';
                font-family: $dagobertFont;
                color: $blue;
                font-size: 40px;
                line-height: 1;
                left: 15px;
                top: 20px;
            }
        }

        .invalid-feedback {
            display: block;
        }

        .form-group {
            position: relative;
        }
    }
}

@include media-breakpoint-down(lg) {
    .knowledgableInvestorContainer {
        .bodyContainer {
            .info {
                padding-right: 20px;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .knowledgableInvestorContainer {
        padding: 50px 50px;

        .bodyContainer {
            padding: 35px 0;

            .projectName {
                margin: 40px 0;
                font-size: 28px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .knowledgableInvestorContainer {
        padding: 20px 30px;

        .headerContainer {
            height: 100px;
            font-size: 20px;
            padding-left: 15px;

            .closeIcon {
                width: 20px;
                height: 20px;
                font-size: 20px;
            }

            .title {
                font-size: 35px;
            }
        }

        .bodyContainer {
            padding: 15px;

            .projectName {
                margin: 20px 0;
                font-size: 26px;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    font-size: 14px;

    .knowledgableInvestorContainer {
        padding: 0;

        .bodyContainer {
            padding: 15px;

            .projectName {
                font-size: 22px;
            }
        }
    }
}
