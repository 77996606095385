h2.top {
    font-size: 38px;
    text-transform: unset;
    margin-top: 0px;

    @include media-breakpoint-down(xs) {
        font-size: 22px;
        text-align: center;
        margin-bottom: 30px;
    }
}

.text {
    margin-bottom: 60px;
    
    @include media-breakpoint-down(xs) {
        text-align: center;
    }
}

.title {
    font-family: $navigationFont;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 1;
}

a {
    &.dropLink {
        position: relative;
        display: block;
        color: inherit;
        padding-top: 15px;
        padding-bottom: 25px;

        &:hover {
            text-decoration: none;
        }

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            content: '\e001';
            font-family: $dagobertFont;
            font-size: 33px;
            color: $dark;
            transform: rotate(270deg);
            transition: all 0.5s linear;
        }
    }

    &.collapsed {
        &::after {
            position: absolute;
            top: 0;
            right: 0;
            content: '\e001';
            font-family: $dagobertFont;
            font-size: 33px;
            color: $dark;
            transform: rotate(90deg);
        }
    }
}

.separator {
    height: 2px;
    background-color: $lighter;
    margin-bottom: 25px;
}

.btn-green {
    display: block;
    margin-top: 20px;
    width: 250px;
}