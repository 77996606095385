#userdata {
    .title {
        font-family: "Exo2";
        font-size: 32px;
        color: $dark;
        margin-bottom: 0px;
        text-transform: uppercase;
        transition: all 0.5s linear;

        .editButton {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-left: 15px;

            img {
                margin-top: -10px;
                height: 20px;
                width: auto;
            }
        }
    }

    .col-form-label {
        font-weight: bold;
    }

    .custom-file-label {
        overflow: hidden;

        &::after {
            content: 'Durchsuche';
        }
    }

    .expiriance2 {
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s linear;

        &.show {
            max-height: 100px;
        }
    }

    .expiriance3 {
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s linear;

        &.show {
            max-height: 400px;
        }
    }

    .pepSelected {
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s linear;

        &.show {
            max-height: 500px;
        }

        i {
            font-size: 14px;
        }
    }

    .pepSpecific {
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s linear;

        &.show {
            max-height: 150px;
        }
    }

    .hint {
        max-height: 0;
        overflow: hidden;
        font-size: 15px;
        border: 0;
        transition: all 0.75s linear;
        padding: 0px;
        margin: 0;

        &.show {
            max-height: 100%;
            padding: 10px;
            border: 1px solid black;
            margin-top: 10px;
        }
    }

    .popoverLink {
        text-decoration: underline;
    }

    .invalid-feedback {
        display: block;
    }

    .form-group {
        position: relative;

        .copyToUser {
            position: absolute;
            top: 5px;
            left: 230px;
        }
    }

    #companyData {
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s linear;

        &.show {
            max-height: 1000px;
        }
    }

    .ibanValidation {
        position: absolute;
        top: 0;
        right: 25px;
        display: block;
        width: 15px;
        height: 34px;

        &.success::after {
            content: '✓';
            color: yellowgreen;
            font-size: 20px;
            display: block;
            width: 15px;
            height: 34px;
            text-align: center;
            line-height: 34px;
            font-weight: bold;
        }

        &.error::after {
            content: '✗';
            color: red;
            font-size: 20px;
            display: block;
            width: 15px;
            height: 34px;
            text-align: center;
            line-height: 34px;
            font-weight: bold;
        }

        &.loader::after {
            content: '';
            border: 2px solid #ffce01;
            border-top: 2px solid #0f3540;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            display: block;
            margin-top: 9px;
            animation: spin 2s linear infinite;
        }
    }

    .ibanCheck {
        max-height: 0;
        overflow: hidden;
        transition: all 0.75s linear;

        &.show {
            max-height: 100%;
        }
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .documentUpload {
        bottom: 20px;
        width: 100%;

        .title {
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 15px;
        }

        .btn-lighter {
            height: 40px;
            text-transform: unset;
        }

        .btn-default {
            text-align: center;
            width: 100%;
            height: 45px;
            font-size: 20px;
            line-height: 43px;
        }
        .doc {
            display: block;
            width: 100%;
            position: absolute;
            bottom: 0px;

            .icon-docadd {
                font-size: 70px;
                line-height: 1;
            }
        }

        &.middle {
            bottom: 1rem;
        }
        
        @include media-breakpoint-down(sm) {
            position: relative;
            margin-top: 30px;

            &.middle {
                bottom: unset;
                top: unset;
            }
        }

        @include media-breakpoint-down(xs) {
            .doc {
                position: relative;  
                margin-top: 30px;
            }
        }
    }
}