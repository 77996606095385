$primaryArticleMarginBottom: 30px;

$primaryArticleTitleMarginTopSm: 36px; // should be the height of two <br>s to match the styling of the other cards

#blog {
    p {
        padding: 0;
        margin: 0;
        margin-bottom: 0;
    }

    .blogMenu {
        background-color: $primary;
        margin-bottom: -20px;
        position: relative;

        .btn {
            padding: 0 30px;
            text-transform: none;
        }

        .btn-secondary {
            color: $white;
        }
    }

    h4 {
        font-weight: 400;
    }

    .grid-item, .grid-sizer {
        width: 33.333%;

        @include media-breakpoint-down(md) {
            width: 50%;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    .grid-item {
        margin-bottom: 30px;
    }

    .card {
        border: 0;
        border-radius: 0;
        padding: 0 15px;
        margin: 0 15px;
    }

    .date {
        color: #808080;
        font-size: 16px;
        font-family: $navigationFont;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .rightCol .boxTitle {
        background-color: #E1E1E1;
        color: #303030;
        font-family: "Exo2";
        font-size: 22px;
        line-height: 45px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 300;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .nl_designer_row_table_col {
        padding: 0px 15px !important;
    }

    a[href=''] {
        pointer-events: none;
    }

    .primary-article {
        padding: 0 (calc($grid-gutter-width / 2));

        @include media-breakpoint-down(sm) {
            margin-bottom: $primaryArticleMarginBottom;

            .date {
                text-align: right;
            }

            h4 {
                margin-top: $primaryArticleTitleMarginTopSm;
                margin-bottom: 1.5rem;
            }
        }
    }

    .image-gallery-container {
        .carousel {
            &.flickity-enabled {
                height: auto !important; // This fixes the non-responsive default behavior of carousel galleries
            }
        }
    }
}
