$columnMarginTopSm: 80px; // should match $singleProjectMarginTopSm
$columnMarginTopXs: 60px; // should match $singleProjectMarginTopXs

.current-projects {
    @include section-spacing;
    overflow: hidden;

    .title {
        @include title-with-icon;
    }

    .action {
        @include frontpage-action;
    }

    .projects {
        > .row {
            @include custom-gutter(60px);

            > [class^='col'] {
                @include media-breakpoint-down(sm) {
                    &:not(:first-child) {
                        margin-top: $columnMarginTopSm;

                        &::before {
                            background-color: $dark;
                            content: '';
                            display: block;
                            height: 1px;
                            left: -(calc($grid-gutter-width / 2));
                            pointer-events: none;
                            position: absolute;
                            top: 0;
                            width: calc(100% + #{$grid-gutter-width});
                        }
                    }
                }

                @include media-breakpoint-down(xs) {
                    &:not(:first-child) {
                        margin-top: $columnMarginTopXs;
                    }
                }
            }
        }
    }
}
