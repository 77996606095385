$dgInputDefaultColor: $gray;

$dgInputBorderColor: #e6e6e6;
$dgInputIconBorderColor: #ccc;
$dgInputIconColor: #999;
$dgInputErrorColor: #ff1400;

$dgInputColors: (
    'primary': $primary,
    'secondary': $secondary,
    'red': $dgInputErrorColor
);


$dgInputBoxShadow: 1px 3px 4px rgba($black, 0.1);

$dgInputFont: $mainFont;

$dgInputFontSize: 15px;
$dgInputFontSizeExtended: 20px;
$dgInputFontSizeReduced: 13px;
$dgInputErrorFontSize: 13px;

$dgInputFontSizeMobile: 13px;
$dgInputFontSizeMobileExtended: 18px;
$dgInputFontSizeMobileReduced: 11px;
$dgInputErrorFontSizeMobile: 11px;

$dgInputLineHeight: 1.5;

$dgInputHeight: 48px;
$dgInputHeightExtended: 52px;
$dgInputHeightReduced: 38px;

$dgInputHeightMobile: 38px;
$dgInputHeightMobileExtended: 44px;
$dgInputHeightMobileReduced: 28px;

$dgInputPaddingHorizontal: 30px;
$dgInputPaddingVertical: calc(calc($dgInputHeight - $dgInputFontSize) / 2);
$dgInputPadding: $dgInputPaddingVertical $dgInputPaddingHorizontal;
$dgInputPaddingHorizontalExtended: 60px;
$dgInputPaddingVerticalExtended: calc(calc($dgInputHeightExtended - $dgInputFontSizeExtended) / 2);
$dgInputPaddingExtended: $dgInputPaddingVerticalExtended $dgInputPaddingHorizontalExtended;
$dgInputPaddingHorizontalReduced: 10px;
$dgInputPaddingVerticalReduced: calc(calc($dgInputHeightReduced - $dgInputFontSizeReduced) / 2);
$dgInputPaddingReduced: $dgInputPaddingVerticalReduced $dgInputPaddingHorizontalReduced;

$dgInputPaddingHorizontalMobile: 15px;
$dgInputPaddingVerticalMobile: calc(calc($dgInputHeightMobile - $dgInputFontSizeMobile) / 2);
$dgInputPaddingMobile: $dgInputPaddingVerticalMobile $dgInputPaddingHorizontalMobile;
$dgInputPaddingHorizontalMobileExtended: 50px;
$dgInputPaddingVerticalMobileExtended: calc(calc($dgInputHeightMobileExtended - $dgInputFontSizeMobileExtended) / 2);
$dgInputPaddingMobileExtended: $dgInputPaddingVerticalMobileExtended $dgInputPaddingHorizontalMobileExtended;
$dgInputPaddingHorizontalMobileReduced: 8px;
$dgInputPaddingVerticalMobileReduced: calc(calc($dgInputHeightMobileReduced - $dgInputFontSizeMobileReduced) / 2);
$dgInputPaddingMobileReduced: $dgInputPaddingVerticalMobileReduced $dgInputPaddingHorizontalMobileReduced;

$dgInputTextAreaHeight: ($dgInputPaddingVertical * 2) + ($dgInputFontSize * $dgInputLineHeight * 5); // 5 lines
$dgInputTextAreaHeightMobile: ($dgInputPaddingVerticalMobile * 2) + ($dgInputFontSizeMobile * $dgInputLineHeight * 5); // 5 lines

$dgInputCheckboxSize: 22px;
$dgInputCheckboxSizeMobile: 16px;

$dgInputRadioSize: 16px;
$dgInputRadioSizeMobile: $dgInputRadioSize;
$dgInputRadioIndicatorSize: 8px;
$dgInputRadioIndicatorSizeMobile: $dgInputRadioIndicatorSize;

// Inputs

.dg-input-wrapper {
    color: $dgInputDefaultColor;
    font-family: $dgInputFont;
    font-size: $dgInputFontSize;
    line-height: $dgInputLineHeight;
    position: relative;

    &.dg-file-input-wrapper {
        display: inline-block;
        min-width: 35%;

        input[type='file'] {
            @include input-reset;

            background-color: $white;
            border: 1px solid $dgInputBorderColor;
            box-shadow: $dgInputBoxShadow;
            color: inherit;
            height: $dgInputHeight + 2; // Accounting for border
            line-height: $dgInputHeight;
            padding: 0 0 0 $dgInputPaddingHorizontal;
            width: 100%;

            &::file-selector-button {
                border: none;
                float: right;
                margin: 0;
                text-transform: uppercase;
                padding: 0 $dgInputPaddingHorizontal;
                background-color: $primary;
                color: white;
            }

            &:focus {
                &:not(:read-only) {
                    border-color: $primary;
                    outline: 0 !important;
                }
            }

            &::placeholder {
                color: $light;
            }

            &.no-shadow {
                box-shadow: none;
            }
        }
    }

    &.dg-text-input-wrapper {
        input[type='text'],
        input[type='date'],
        input[type='email'],
        input[type='password'],
        input[type='number'],
        input[type='tel'],
        input[type='search'],
        input[type='url'] {
            @include input-reset;

            background-color: $white;
            border: 1px solid $dgInputBorderColor;
            box-shadow: $dgInputBoxShadow;
            color: inherit;
            height: $dgInputHeight + 2; // Accounting for border
            line-height: $dgInputHeight;
            padding: 0 $dgInputPaddingHorizontal;
            min-width: 275px;
            width: 100%;

            &:focus {
                &:not(:read-only) {
                    border-color: $primary;
                    outline: 0 !important;
                }
            }

            &::placeholder {
                color: $light;
            }

            &.no-shadow {
                box-shadow: none;
            }
        }

        .dg-input-label {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            white-space: nowrap;
            border: 1px solid transparent;

            line-height: $dgInputHeight;
            padding: 0 $dgInputPaddingHorizontal;
            width: 100%;

            pointer-events: none;

            .dg-input-required {
                color: $yellow;
            }

            @include media-breakpoint-down(sm) {
                font-size: $dgInputFontSizeMobile;
                height: $dgInputHeightMobile + 2; // Accounting for border
                line-height: $dgInputHeightMobile;
                padding: 0 $dgInputPaddingHorizontalMobile;
            }

        }

        input:focus + .dg-input-label,
        input:not(:placeholder-shown) + .dg-input-label {
            display: none;
        }

        textarea {
            @include input-reset;

            background-color: $white;
            border: 1px solid $dgInputBorderColor;
            box-shadow: $dgInputBoxShadow;
            color: inherit;
            height: $dgInputTextAreaHeight;
            padding: $dgInputPadding;
            vertical-align: top;
            width: 100%;

            &:focus {
                &:not(:read-only) {
                    border-color: $primary;
                    outline: 0 !important;
                }
            }

            &::placeholder {
                color: $light;
            }

            &.no-shadow {
                box-shadow: none;
            }
        }
    }

    &.dg-checkbox-input-wrapper {
        position: relative;

        label {
            margin: 0 !important;
            padding-left: $dgInputCheckboxSize + $dgInputPaddingHorizontal;
            position: relative;

            &::before {
                background-color: $white;
                border: 1px solid $dgInputIconBorderColor;
                border-radius: 4px;
                color: $secondary;
                cursor: pointer;
                content: '';
                font-family: 'dagobertinvest';
                height: $dgInputCheckboxSize;
                line-height: $dgInputCheckboxSize;
                left: 0;
                position: absolute;
                text-align: center;
                top: calc(calc(($dgInputFontSize * $dgInputLineHeight) - $dgInputCheckboxSize) / 2);
                width: $dgInputCheckboxSize;
            }
        }

        input[type='checkbox'] {
            @include input-reset;

            position: absolute;

            &:focus {
                + label {
                    &::before {
                        border-color: $primary;
                    }
                }
            }

            &:checked {
                + label {
                    &::before {
                        content: '\e031';
                    }
                }
            }
        }
    }

    &.dg-radio-input-wrapper {
        label {
            margin: 0 !important;
            padding-left: $dgInputRadioSize + $dgInputPaddingHorizontal;
            position: relative;

            &::before {
                background-color: $white;
                border: 1px solid $dgInputIconBorderColor;
                border-radius: 50%;
                color: $secondary;
                cursor: pointer;
                content: '';
                height: $dgInputRadioSize;
                left: 0;
                position: absolute;
                top: calc(calc(($dgInputFontSize * $dgInputLineHeight) - $dgInputRadioSize) / 2);
                width: $dgInputRadioSize;
            }

            &::after {
                border-radius: 50%;
                content: '';
                cursor: pointer;
                height: $dgInputRadioIndicatorSize;
                left: calc(($dgInputRadioSize - $dgInputRadioIndicatorSize) / 2);
                position: absolute;
                top: calc(calc(($dgInputFontSize * $dgInputLineHeight) - $dgInputRadioIndicatorSize) / 2);
                width: $dgInputRadioIndicatorSize;
            }
        }

        input[type='radio'] {
            @include input-reset;

            position: absolute;

            &:focus {
                + label {
                    &::before {
                        border-color: $primary;
                    }
                }
            }

            &:checked {
                + label {
                    &::after {
                        background-color: $primary;
                    }
                }
            }
        }
    }

    &.dg-select-input-wrapper {
        &::before {
            align-items: center;
            border-left: 1px solid $dgInputIconBorderColor;
            bottom: calc($dgInputPaddingVertical / 2);
            content: '\e036';
            font-family: 'dagobertinvest';
            display: flex;
            line-height: 0; // FIXME: this doesn't vertically line up perfectly with the error icon for some reason
            justify-content: center;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: calc($dgInputPaddingVertical / 2);
            text-align: center;
            width: $dgInputPaddingHorizontal * 2;
        }

        select {
            @include input-reset;

            background-color: $white;
            border: 1px solid $dgInputBorderColor;
            box-shadow: $dgInputBoxShadow;
            color: inherit;
            cursor: pointer;
            height: $dgInputHeight + 2; // Accounting for border
            line-height: $dgInputHeight;
            padding: 0 ($dgInputPaddingHorizontal * 2.5) 0 $dgInputPaddingHorizontal;
            width: 100%;

            &:focus {
                border-color: $primary;
                outline: 0 !important;
            }

            &.no-shadow {
                box-shadow: none;
            }

            &.input-small {
                height: 28px;
                line-height: 26px;
            }
        }
    }

    &.dg-multi-select-input-wrapper {
        .ts-control {
            border: 1px solid $dgInputBorderColor;
            border-radius: 0;
            box-shadow: $dgInputBoxShadow;
            padding: 10.25px !important; // FIXME: This is to achieve $dgInputHeight, but should be calculated instead

            input {
                min-height: 27.5px !important; // FIXME: This is to achieve $dgInputHeight, but should be calculated instead
            }

            > div {
                background: $almostWhite;
                color: $default;
            }
        }

        .ts-wrapper {
            &.focus {
                .ts-control {
                    border-color: $primary;
                }
            }
        }

        .ts-dropdown {
            color: $dgInputDefaultColor;

            .active {
                background-color: $almostWhite;
                color: $default;
            }
        }

        &.no-shadow {
            .ts-control {
                box-shadow: none;
            }
        }
    }

    &.dg-file-input-wrapper {
        display: flex;

        input[type='file'] {
            cursor: pointer;
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 10;
        }

        .dg-file-input-selected-file {
            background-color: $white;
            border: 1px solid $dgInputBorderColor;
            box-shadow: $dgInputBoxShadow;
            color: inherit;
            flex: 1;
            height: $dgInputHeight + 2; // Accounting for border
            line-height: $dgInputHeight;
            overflow-x: hidden;
            padding: 0 $dgInputPaddingHorizontal;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
        }

        .dg-file-input-button {
            display: block;
            height: $dgInputHeight + 2; // Accounting for border
            line-height: $dgInputHeight + 2; // Accounting for border
            padding: 0 $dgInputPaddingHorizontal;
            text-align: center;
            text-transform: uppercase;

            @include dg-button-variant($primary, $white, true);
        }

        &.no-shadow {
            .dg-file-input-value {
                box-shadow: none;
            }
        }
    }

    &.has-error {
        &.dg-text-input-wrapper {
            &::after {
                color: $dgInputErrorColor;
                content: '\e034';
                display: block;
                font-family: 'dagobertinvest';
                height: $dgInputPaddingHorizontal;
                line-height: $dgInputPaddingHorizontal;
                pointer-events: none;
                position: absolute;
                right: 0;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
                width: $dgInputPaddingHorizontal * 1.5;
            }

            input[type='date'],
            input[type='file'],
            input[type='text'],
            input[type='email'],
            input[type='password'],
            input[type='number'],
            input[type='tel'],
            input[type='search'],
            input[type='url'],
            textarea {
                border-color: $dgInputErrorColor;
                padding-right: $dgInputPaddingHorizontal * 1.5;
            }
        }

        &.dg-checkbox-input-wrapper,
        &.dg-radio-input-wrapper {
            label {
                color: $dgInputErrorColor;
            }
        }

        &.dg-select-input-wrapper {
            &::after {
                color: $dgInputErrorColor;
                content: '\e034';
                display: block;
                font-family: 'dagobertinvest';
                height: $dgInputPaddingHorizontal;
                line-height: $dgInputPaddingHorizontal;
                pointer-events: none;
                position: absolute;
                right: $dgInputPaddingHorizontal * 2;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
                width: $dgInputPaddingHorizontal * 1.5;
            }

            select {
                border-color: $dgInputErrorColor;
                padding-right: $dgInputPaddingHorizontal * 3.5;
            }
        }

        &.dg-multi-select-input-wrapper {
            .ts-control {
                border-color: $dgInputErrorColor;
            }
        }

        &.dg-file-input-wrapper {
            .dg-file-input-selected-file {
                border-color: $dgInputErrorColor;
            }
        }
    }

    &.has-icon {
        &.dg-text-input-wrapper {
            input[type='date'],
            input[type='text'],
            input[type='email'],
            input[type='password'],
            input[type='number'],
            input[type='tel'],
            input[type='search'],
            input[type='url'],
            textarea {
                padding-right: $dgInputPaddingHorizontal * 2.5; // .5 to to directly end on the border line of the icon
            }

            > [class^='icon-'] {
                align-items: center;
                border-left: 1px solid $dgInputIconBorderColor;
                bottom: calc($dgInputPaddingVertical / 2);
                color: $dgInputIconColor;
                display: flex;
                justify-content: center;
                pointer-events: none;
                position: absolute;
                right: 0;
                top: calc($dgInputPaddingVertical / 2);
                text-align: center;
                width: $dgInputPaddingHorizontal * 2;
            }

            &.has-error {
                &::after {
                    right: $dgInputPaddingHorizontal * 2;
                }

                input[type='text'],
                input[type='date'],
                input[type='email'],
                input[type='password'],
                input[type='number'],
                input[type='tel'],
                input[type='search'],
                input[type='url'],
                textarea {
                    padding-right: $dgInputPaddingHorizontal * 3.5;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: $dgInputFontSizeMobile;

        &.dg-text-input-wrapper {
            input[type='date'],
            input[type='text'],
            input[type='email'],
            input[type='password'],
            input[type='number'],
            input[type='tel'],
            input[type='search'],
            input[type='url'] {
                height: $dgInputHeightMobile + 2; // Accounting for border
                line-height: $dgInputHeightMobile;
                padding: 0 $dgInputPaddingHorizontalMobile;
            }

            textarea {
                height: $dgInputTextAreaHeightMobile;
                padding: $dgInputPaddingMobile;
            }
        }

        &.dg-checkbox-input-wrapper {
            label {
                padding-left: $dgInputCheckboxSizeMobile + $dgInputPaddingHorizontalMobile;

                &::before {
                    height: $dgInputCheckboxSizeMobile;
                    line-height: $dgInputCheckboxSizeMobile;
                    top: calc(calc(($dgInputFontSizeMobile * $dgInputLineHeight) - $dgInputCheckboxSizeMobile) / 2);
                    width: $dgInputCheckboxSizeMobile;
                }
            }
        }

        &.dg-radio-input-wrapper {
            label {
                padding-left: $dgInputRadioSizeMobile + $dgInputPaddingHorizontalMobile;

                &::before {
                    height: $dgInputRadioSizeMobile;
                    top: calc(calc(($dgInputFontSizeMobile * $dgInputLineHeight) - $dgInputRadioSizeMobile) / 2);
                    width: $dgInputRadioSizeMobile;
                }

                &::after {
                    height: $dgInputRadioIndicatorSizeMobile;
                    left: calc(($dgInputRadioSizeMobile - $dgInputRadioIndicatorSizeMobile) / 2);
                    top: calc(calc(($dgInputFontSizeMobile * $dgInputLineHeight) - $dgInputRadioIndicatorSizeMobile) / 2);
                    width: $dgInputRadioIndicatorSizeMobile;
                }
            }
        }

        &.dg-select-input-wrapper {
            &::before {
                width: $dgInputPaddingHorizontalMobile * 2;
            }

            select {
                height: $dgInputHeightMobile + 2; // Accounting for border
                line-height: $dgInputHeightMobile;
                padding: 0 ($dgInputPaddingHorizontalMobile * 2.5) 0 $dgInputPaddingHorizontalMobile; // .5 to to directly end on the border line of the icon
            }
        }

        &.dg-multi-select-input-wrapper {
            .ts-control {
                padding: 6.75px !important; // FIXME: This is to achieve $dgInputHeightMobile, but should be calculated instead

                input {
                    min-height: 24.5px !important; // FIXME: This is to achieve $dgInputHeight, but should be calculated instead
                }
            }
        }

        &.dg-file-input-wrapper {
            .dg-file-input-selected-file {
                height: $dgInputHeightMobile + 2; // Accounting for border
                line-height: $dgInputHeightMobile;
                padding: 0 $dgInputPaddingHorizontalMobile;
            }

            .dg-file-input-button {
                height: $dgInputHeightMobile + 2; // Accounting for border
                line-height: $dgInputHeightMobile + 2; // Accounting for border
                padding: 0 $dgInputPaddingHorizontalMobile;
            }
        }

        &.has-error {
            &.dg-text-input-wrapper {
                &::after {
                    height: $dgInputPaddingHorizontalMobile;
                    line-height: $dgInputPaddingHorizontalMobile;
                    width: $dgInputPaddingHorizontalMobile * 1.5;
                }

                input[type='date'],
                input[type='text'],
                input[type='email'],
                input[type='password'],
                input[type='number'],
                input[type='tel'],
                input[type='search'],
                input[type='url'],
                textarea {
                    padding-right: $dgInputPaddingHorizontalMobile * 1.5;
                }
            }

            &.dg-select-input-wrapper {
                &::after {
                    height: $dgInputPaddingHorizontalMobile;
                    line-height: $dgInputPaddingHorizontalMobile;
                    right: $dgInputPaddingHorizontalMobile * 2;
                    width: $dgInputPaddingHorizontalMobile * 1.5;
                }

                select {
                    padding-right: $dgInputPaddingHorizontalMobile * 3.5;
                }
            }
        }

        &.has-icon {
            &.dg-text-input-wrapper {
                input[type='text'],
                input[type='date'],
                input[type='email'],
                input[type='password'],
                input[type='number'],
                input[type='tel'],
                input[type='search'],
                input[type='url'],
                textarea {
                    padding-right: $dgInputPaddingHorizontalMobile * 2.5; // .5 to to directly end on the border line of the icon
                }

                > [class^='icon-'] {
                    width: $dgInputPaddingHorizontalMobile * 2;
                }

                &.has-error {
                    &::after {
                        right: $dgInputPaddingHorizontalMobile * 2;
                    }

                    input[type='text'],
                    input[type='date'],
                    input[type='email'],
                    input[type='password'],
                    input[type='number'],
                    input[type='tel'],
                    input[type='search'],
                    input[type='url'],
                    textarea {
                        padding-right: $dgInputPaddingHorizontalMobile * 3.5;
                    }
                }
            }
        }
    }
}

.dg-input-file-list {
    list-style: none;
    padding: 15px 0 0 0;
    margin: 0;

    .dg-input-file-list {
        padding-left: 30px;

        .dg-input-file-list-item {
            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    a {
        text-decoration: none;
        display: inline-flex;
        align-items: baseline;

        &.folder {
            color: $gray;
            pointer-events: none;
        }
    }

    .dg-input-file-list-item {
        padding-right: 15px;
        padding-bottom: 15px;
        display: inline-flex;
        align-items: baseline;
    }

    .file-name {
        margin: 0 5px;
        max-width: 150px;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
    }

    .file-icon {
        width: 100%;
        height: auto;
        max-width: 20px;
    }

    .file-delete {
        width: 100%;
        height: auto;
        max-width: 15px;
        align-self: flex-start;
        cursor: pointer;
    }

    &.is-multiline {
        .dg-input-file-list-item {
            display: block;
            padding-right: 0;
        }

        .file-name {
            max-width: none;
        }
    }
}

// Errors

.dg-input-error {
    color: $dgInputErrorColor;
    font-size: $dgInputErrorFontSize;
    margin-top: 5px;

    &.has-icon {
        align-items: center;
        display: flex;
        gap: 10px;

        &::before {
            color: $dgInputErrorColor;
            content: '\e034';
            display: inline-block;
            font-family: 'dagobertinvest';
            font-size: 120%;
            pointer-events: none;
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: $dgInputErrorFontSizeMobile;
    }
}

// Info Boxes

.dg-info-box {
    cursor: initial; // Display the default cursor even if the info box is nested in, e.g., an anchor
    position: absolute;
    z-index: 10;

    .dg-info-box-button {
        position: absolute;
        right: 0;
        top: 0;

        button {
            @include input-reset;

            font-size: 18px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            width: 20px;

            &::before {
                color: $primary;
                content: '\e039';
                font-family: 'dagobertinvest';
            }
        }
    }

    .dg-info-box-content {
        background-color: #fcfcfc;
        border: 1px solid $dgInputBorderColor;
        box-shadow: $dgInputBoxShadow;
        display: none;
        margin-top: 30px;
        padding: 20px;
        position: relative;
        text-align: center;
        white-space: initial !important;
        width: 290px;

        .dg-info-box-close {
            position: absolute;
            right: 15px;
            top: 15px;

            button {
                @include input-reset;

                font-size: 14px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                width: 20px;

                &::before {
                    color: #999;
                    content: '\e003';
                    font-family: 'dagobertinvest';
                }
            }
        }

        .dg-info-box-title {
            border-bottom: 1px solid #999;
            color: $primary;
            font-weight: 500;
            font-size: 14px;
            padding: 0 20px 10px;
        }

        .dg-info-box-text {
            color: $dgInputDefaultColor;
            font-size: 13px;
            margin-top: 15px;

            a,
            .dg-info-box-anchor {
                color: $primary;
                cursor: pointer; // Explicitly set the cursor to pointer for links because we're also overriding the default cursor of the info box with `cursor: initial`
                text-decoration: underline;
            }
        }
    }

    &.active {
        z-index: 11;

        .dg-info-box-content {
            display: block;
        }
    }

    @include media-breakpoint-down(sm) {
        .dg-info-box-content {
            left: 50%;
            max-height: 80%;
            max-width: 80%;
            overflow: auto;
            position: fixed;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &.active {
            &::before {
                background-color: transparentize($black, 0.5);
                content: '';
                display: block;
                height: 100%;
                left: 0;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: -1;
            }
        }
    }
}

// Buttons

.dg-button {
    @include input-reset;

    &:not(.colors-inverted) {
        @include dg-button-variant($white, $dgInputDefaultColor, false);
    }

    &.colors-inverted {
        @include dg-button-variant($white, $dgInputDefaultColor, false, true);
    }

    box-shadow: $dgInputBoxShadow;
    color: $dgInputDefaultColor;
    display: inline-block;
    font-weight: 400;
    font-family: $dgInputFont;
    font-size: $dgInputFontSize;
    height: auto;
    line-height: 1;
    padding: $dgInputPadding;
    text-align: center;
    text-decoration: none !important;
    text-transform: uppercase;
    transition:
        background-color 150ms ease,
        border-color 150ms ease,
        color 150ms ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
    width: auto;

    &.no-shadow {
        box-shadow: none;
    }

    &.width-full {
        width: 100%;
    }

    &.no-uppercase {
        text-transform: none;
    }

    &.has-icon {
        .dg-button-content {
            align-items: center;
            display: flex;
            gap: $dgInputPaddingHorizontal;
            justify-content: center;
        }

        [class^='icon-'] {
            font-size: 80%;
            pointer-events: none;
        }
    }

    &.size-extended {
        font-size: $dgInputFontSizeExtended;
        padding: $dgInputPaddingExtended;
    }

    &.size-reduced {
        font-size: $dgInputFontSizeReduced;
        padding: $dgInputPaddingReduced;
    }

    @each $colorName, $color in $dgInputColors {
        &.color-#{$colorName} {
            &:not(.colors-inverted) {
                @include dg-button-variant($color, $white, true);

                &.default-color-on-active {
                    &:focus {
                        &:not([disabled]) {
                            color: $dgInputDefaultColor !important;
                        }
                    }

                    @media (hover: hover) {
                        &:hover {
                            &:not([disabled]) {
                                color: $dgInputDefaultColor !important;
                            }
                        }
                    }
                }
            }

            &.colors-inverted {
                @include dg-button-variant($color, $white, true, true);

                &.default-color-on-active {
                    &[disabled] {
                        color: $dgInputDefaultColor !important;
                    }

                    &:not(:focus) {
                        &:not(:hover) {
                            color: $dgInputDefaultColor !important;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: $dgInputFontSizeMobile;
        padding: $dgInputPaddingMobile;

        &.has-icon {
            .dg-button-content {
                gap: $dgInputPaddingHorizontalMobile;
            }
        }

        &.size-extended {
            font-size: $dgInputFontSizeMobileExtended;
            padding: $dgInputPaddingMobileExtended;
        }

        &.size-reduced {
            font-size: $dgInputFontSizeMobileReduced;
            padding: $dgInputPaddingMobileReduced;
        }
    }
}

.dg-radio-button-input-wrapper {
    width: 100%;

    input[type="radio"] {
        display: none;
    }
    .dg-button {
        cursor: pointer;
        width: 100%;

        &.has-icon {
            .dg-button-content {
                padding-left: 45px;
            }

            i[class^='icon'] {
                transition: opacity 150ms ease;
                opacity: 0;
            }
        }
    }

    input[type="radio"]:checked + .dg-button {
        &.has-icon {
            i[class^='icon'] {
                opacity: 1;
            }
        }

        font-weight: bold;
        background-color: $white;
        color: $dgInputDefaultColor;
        border-color: $dgInputDefaultColor;

        @each $colorName, $color in $dgInputColors {
            &.color-#{$colorName} {
                &:not(.colors-inverted) {
                    color: $color;
                    border-color: $color;
                    background-color: $white;

                    &.default-color-on-active {
                        color: $dgInputDefaultColor !important;
                    }
                }

                &.colors-inverted {
                    color: $white;
                    border-color: $color;
                    background-color: $color;

                    &.default-color-on-active {
                        color: $dgInputDefaultColor !important;
                    }
                }
            }
        }

    }
}

.dg-spacer-input-wrapper {
    display: block;
    height: 50px;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}
