.login-teaser {
    margin: 80px 0;
    padding: 0 ($grid-gutter-width / 2) 50px; // To align with the rest of the content
    position: relative;

    &__fade {
        mask-image: linear-gradient(180deg, transparentize($white, 0.5) 20%, transparent);
        pointer-events: none;
    }

    &__action {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 10;

        &__description {
            margin-bottom: 20px;
        }
    }
}
