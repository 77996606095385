h2.top {
    font-size: 38px;
    text-transform: unset;
    margin-top: 0px;

    b {
        font-weight: 700;
    }

    @include media-breakpoint-down(xs) {
        font-size: 22px;
        text-align: center;
        margin-bottom: 30px;
    }
}

.text {
    margin-bottom: 60px;

    .btn {
        display: inline-block;
        margin-top: 20px;
    }

    .bg-mediumBlue {
        position: relative;
        color: white;
        font-size: 44px;
        font-family: $font-alt;
        font-weight: 300;
        padding: 30px 60px 155px 60px;
        line-height: 1.5;
        text-transform: uppercase;

        &::before {
            position: absolute;
            bottom: 0;
            right: 60px;
            content: '\e005';
            font-family: $dagobertFont;
            color: $ultraLightBlue;
            font-size: 125px;
            line-height: 1;
        }
    }

    @include media-breakpoint-down(lg) {
        .bg-mediumBlue {
            font-size: 38px;
            padding: 30px 60px 140px 60px;
    
            &::before {
                font-size: 110px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .bg-mediumBlue {
            font-size: 34px;
            padding: 25px 25px 130px 25px;
    
            &::before {
                right: 25px;
                font-size: 100px;
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        text-align: center;

        .bg-mediumBlue {
            font-size: 24px;
            padding: 25px 25px 90px 25px;
    
            &::before {
                right: 25px;
                font-size: 70px;
            }
        }
    }
}