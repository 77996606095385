#registrationSuccess {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 0vh;
    background: $lightBlue;
    z-index: 11;
    overflow: hidden;
    transition: height 0.3s;

    &.show {
        height: 100vh;
    }

    .registrationSuccessContainer {
        width: 100%;
        height: 100vh;
        overflow: auto;
        padding: 100px 175px;

        .headerContainer {
            position: relative;
            background: $yellow;
            height: 160px;
            color: $lessDark;
            text-transform: uppercase;
            font-family: $font-alt;
            font-size: 30px;
            padding-top: 25px;
            line-height: 1;
    
            .closeIcon {
                cursor: pointer;
                position: absolute;
                display: block;
                width: 35px;
                height: 35px;
                line-height: 1;
                font-size: 35px;
                right: 20px;
                top: 25px;
                z-index: 12;
            }
            
            .title {
                font-size: 64px;
            }

            .headerImage {
                position: absolute;
                right: 30px;
                top: 90px;
                display: block;
                width: 250px;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .bodyContainer {
            background: white;
            padding: 0 15px 15px 15px;

            .errorFrame {
                background-color: rgba($color: $red, $alpha: 0.3);
                border-bottom: 1px $red solid;
                color: $red;
                font-weight: bold;
                min-height: 40px;
                line-height: 40px;
            }

            .success {
                font-size: 36px;
                font-family: $navigationFont;
                color: $lessDark;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .registrationSuccessContainer {
            padding: 50px 50px;

            .bodyContainer {
                padding: 75px 0;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .registrationSuccessContainer {
            padding: 50px 50px;

            .headerContainer {
                font-size: 25px;

                .title {
                    font-size: 50px;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .registrationSuccessContainer {
            padding: 20px 30px;

            .headerContainer {
                height: 100px;
                font-size: 20px;
                padding-left: 15px;

                .headerImage {
                    top: 55px;
                    width: 150px;
                }
        
                .closeIcon {
                    width: 20px;
                    height: 20px;
                    font-size: 20px;
                }
                
                .title {
                    font-size: 35px;
                }
            }

            .bodyContainer {
                padding: 30px 15px;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        font-size: 14px;

        .registrationSuccessContainer {
            padding: 0;

            .headerContainer {
                font-size: 15px;
                
                .title {
                    font-size: 20px;
                }

                .headerImage {
                    top: 35px;
                    width: 150px;
                }
            }

            .bodyContainer {
                padding: 40px 15px;
            }
        }
    }
}