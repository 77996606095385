@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-black.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-black.woff') format('woff'), 
         url('../../fonts/exo2/exo2-black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-blackitalic.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-blackitalic.woff') format('woff'), 
         url('../../fonts/exo2/exo2-blackitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-extrabold.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-extrabold.woff') format('woff'), 
         url('../../fonts/exo2/exo2-extrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-extrabolditalic.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-extrabolditalic.woff') format('woff'), 
         url('../../fonts/exo2/exo2-extrabolditalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-bold.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-bold.woff') format('woff'), 
         url('../../fonts/exo2/exo2-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-bolditalic.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-bolditalic.woff') format('woff'), 
         url('../../fonts/exo2/exo2-bolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-semibold.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-semibold.woff') format('woff'), 
         url('../../fonts/exo2/exo2-semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-semibolditalic.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-semibolditalic.woff') format('woff'), 
         url('../../fonts/exo2/exo2-semibolditalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-medium.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-medium.woff') format('woff'), 
         url('../../fonts/exo2/exo2-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-mediumitalic.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-mediumitalic.woff') format('woff'), 
         url('../../fonts/exo2/exo2-mediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-regular.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-regular.woff') format('woff'), 
         url('../../fonts/exo2/exo2-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-italic.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-italic.woff') format('woff'), 
         url('../../fonts/exo2/exo2-italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-light.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-light.woff') format('woff'), 
         url('../../fonts/exo2/exo2-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-lightitalic.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-lightitalic.woff') format('woff'), 
         url('../../fonts/exo2/exo2-lightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-extralight.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-extralight.woff') format('woff'), 
         url('../../fonts/exo2/exo2-extralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-extralightitalic.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-extralightitalic.woff') format('woff'), 
         url('../../fonts/exo2/exo2-extralightitalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-thin.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-thin.woff') format('woff'), 
         url('../../fonts/exo2/exo2-thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo2';
    src: url('../../fonts/exo2/exo2-thinitalic.woff2') format('woff2'), 
         url('../../fonts/exo2/exo2-thinitalic.woff') format('woff'), 
         url('../../fonts/exo2/exo2-thinitalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}