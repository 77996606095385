$itemLineWidth: 4px;
$itemLineWidthSm: 2px;

$itemIconSize: 100px;
$itemIconSizeSm: 45px;
$itemIconSizeFontSize: 50px;
$itemIconSizeFontSizeSm: 25px;

$itemTitleFontSize: 40px;
$itemTitleFontSizeSm: 20px;
$itemTitlePadding: 20px 0 5px;
$itemTitlePaddingSm: 10px 0 5px;
$itemPhaseLabelFontSize: 32px;
$itemPhaseLabelFontSizeSm: 16px;
$itemPhaseLabelMarginRight: 30px;
$itemPhaseLabelMarginRightSm: 10px;

$itemContentPadding: 30px 0 100px;
$itemContentPaddingSm: 20px 0 30px;
$itemContentFontSize: 16px;
$itemContentFontSizeSm: 12px;
$itemSubStepPaddingLeft: 45px;
$itemSubStepPaddingLeftSm: 30px;
$itemSubStepIndicatorSize: 10px;
$itemSubStepIndicatorSizeSm: 8px;
$itemSubStepIndicatorTop: 6px;
$itemSubStepIndicatorTopSm: 4px;
$itemSubStepTitleSize: 18px;
$itemSubStepTitleSizeSm: 14px;
$itemSubStepTitleMargin: 0 0 10px;

$phase1Color: #02a2c8;
$phase2Color: #00c8ff;
$phase3Color: #88ae42;
$phase4Color: #fbb90b;
$phase5Color: #e7861d;

.steps-timeline {
    @include section-spacing;

    &.no-spacing {
        padding-top: 0;
    }

    &.negate-container-spacing {
        margin-left: -(calc($grid-gutter-width / 2));
        margin-right: -(calc($grid-gutter-width / 2));
    }

    .items {
        .item {
            padding-left: calc($itemIconSize * 1.25);
            position: relative;

            @include media-breakpoint-down(sm) {
                padding-left: calc($itemIconSizeSm * 1.25);
            }

            &::before {
                background-color: $primary;
                content: '';
                display: block;
                height: 100%;
                left: calc($itemIconSize / 2);
                pointer-events: none;
                position: absolute;
                top: 0;
                transform: translateX(-50%);
                width: $itemLineWidth;

                @include media-breakpoint-down(sm) {
                    left: calc($itemIconSizeSm / 2);
                    width: $itemLineWidthSm;
                }
            }

            .icon {
                box-shadow: 0 2px 6px 0 transparentize($black, 0.75);
                background-color: $white;
                border: 2px solid;
                border-radius: 50%;
                color: $primary;
                font-size: $itemIconSizeFontSize;
                height: $itemIconSize;
                left: 0;
                line-height: $itemIconSize;
                pointer-events: none;
                position: absolute;
                text-align: center;
                top: 0;
                width: $itemIconSize;
                z-index: 10;

                @include media-breakpoint-down(sm) {
                    font-size: $itemIconSizeFontSizeSm;
                    height: $itemIconSizeSm;
                    line-height: $itemIconSizeSm;
                    width: $itemIconSizeSm;
                }
            }

            .title {
                border-bottom: 1px solid;
                color: $primary;
                padding: $itemTitlePadding;

                @include media-breakpoint-down(sm) {
                    padding: $itemTitlePaddingSm;
                }

                &::before {
                    color: inherit;
                    font-family: $headerFont;
                    font-size: $itemPhaseLabelFontSize;
                    font-weight: 500;
                    margin-right: $itemPhaseLabelMarginRight;
                    white-space: nowrap;

                    @include media-breakpoint-down(sm) {
                        font-size: $itemPhaseLabelFontSizeSm;
                        margin-right: $itemPhaseLabelMarginRightSm;
                    }
                }

                h2 {
                    color: inherit;
                    font-size: $titleFontSize;
                    font-weight: 500;
                    margin: 0;
                    text-transform: uppercase;

                    @include media-breakpoint-down(sm) {
                        font-size: $itemTitleFontSizeSm;
                    }
                }
            }

            .content {
                color: $lessDark;
                font-family: $mainFont;
                font-size: $itemContentFontSize;
                padding: $itemContentPadding;

                @include media-breakpoint-down(sm) {
                    font-size: $itemContentFontSizeSm;
                    padding: $itemContentPaddingSm;
                }

                > p,
                > ol,
                > ul {
                    margin-bottom: 30px !important;

                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                }

                > ul {
                    list-style-type: none;
                    padding: 0;

                    li {
                        padding-left: 2em;
                        position: relative;

                        &::before {
                            background-color: $primary;
                            border-radius: 50%;
                            content: '';
                            display: block;
                            height: $itemSubStepIndicatorSize;
                            left: 0;
                            pointer-events: none;
                            position: absolute;
                            top: $itemSubStepIndicatorTop;
                            width: $itemSubStepIndicatorSize;

                            @include media-breakpoint-down(sm) {
                                height: $itemSubStepIndicatorSizeSm;
                                top: $itemSubStepIndicatorTopSm;
                                width: $itemSubStepIndicatorSizeSm;
                            }
                        }
                    }
                }

                b,
                strong {
                    font-weight: 600;
                }

                a {
                    color: $primary;

                    &:hover {
                        color: $primary;
                    }
                }

                .sub-step {
                    padding-left: $itemSubStepPaddingLeft;
                    position: relative;

                    @include media-breakpoint-down(sm) {
                        padding-left: $itemSubStepPaddingLeftSm;
                    }

                    &::before {
                        background-color: $primary;
                        border-radius: 50%;
                        content: '';
                        display: block;
                        height: $itemSubStepIndicatorSize;
                        left: 0;
                        pointer-events: none;
                        position: absolute;
                        top: $itemSubStepIndicatorTop;
                        width: $itemSubStepIndicatorSize;

                        @include media-breakpoint-down(sm) {
                            height: $itemSubStepIndicatorSizeSm;
                            top: $itemSubStepIndicatorTopSm;
                            width: $itemSubStepIndicatorSizeSm;
                        }
                    }

                    h3 {
                        font-family: $mainFont;
                        font-size: $itemSubStepTitleSize;
                        font-weight: 700;
                        margin: $itemSubStepTitleMargin;
                        text-transform: none;

                        @include media-breakpoint-down(sm) {
                            font-size: $itemSubStepTitleSizeSm;
                        }
                    }

                    p {
                        margin-bottom: 30px !important;
                    }
                }
            }

            &.phase-1 {
                &::before {
                    background-color: $phase1Color;
                }

                .icon {
                    color: $phase1Color;
                }

                .title {
                    align-items: baseline;
                    color: $phase1Color;
                    display: flex;

                    &::before {
                        content: 'Phase 1.';
                    }
                }

                .content {
                    > ul {
                        li {
                            &::before {
                                background-color: $phase1Color;
                            }
                        }
                    }

                    a {
                        color: $phase1Color;

                        &:hover {
                            color: $phase1Color;
                        }
                    }

                    .sub-step {
                        &::before {
                            background-color: $phase1Color;
                        }
                    }
                }
            }

            &.phase-2 {
                &::before {
                    background-color: $phase2Color;
                }

                .icon {
                    color: $phase2Color;
                }

                .title {
                    align-items: baseline;
                    color: $phase2Color;
                    display: flex;

                    &::before {
                        content: 'Phase 2.';
                    }
                }

                .content {
                    > ul {
                        li {
                            &::before {
                                background-color: $phase2Color;
                            }
                        }
                    }

                    a {
                        color: $phase2Color;

                        &:hover {
                            color: $phase2Color;
                        }
                    }

                    .sub-step {
                        &::before {
                            background-color: $phase2Color;
                        }
                    }
                }
            }

            &.phase-3 {
                &::before {
                    background-color: $phase3Color;
                }

                .icon {
                    color: $phase3Color;
                }

                .title {
                    align-items: baseline;
                    color: $phase3Color;
                    display: flex;

                    &::before {
                        content: 'Phase 3.';
                    }
                }

                .content {
                    > ul {
                        li {
                            &::before {
                                background-color: $phase3Color;
                            }
                        }
                    }

                    a {
                        color: $phase3Color;

                        &:hover {
                            color: $phase3Color;
                        }
                    }

                    .sub-step {
                        &::before {
                            background-color: $phase3Color;
                        }
                    }
                }
            }

            &.phase-4 {
                &::before {
                    background-color: $phase4Color;
                }

                .icon {
                    color: $phase4Color;
                }

                .title {
                    align-items: baseline;
                    color: $phase4Color;
                    display: flex;

                    &::before {
                        content: 'Phase 4.';
                    }
                }

                .content {
                    > ul {
                        li {
                            &::before {
                                background-color: $phase4Color;
                            }
                        }
                    }

                    a {
                        color: $phase4Color;

                        &:hover {
                            color: $phase4Color;
                        }
                    }

                    .sub-step {
                        &::before {
                            background-color: $phase4Color;
                        }
                    }
                }
            }

            &.phase-5 {
                &::before {
                    background-color: $phase5Color;
                }

                .icon {
                    color: $phase5Color;
                }

                .title {
                    align-items: baseline;
                    color: $phase5Color;
                    display: flex;

                    &::before {
                        content: 'Phase 5.';
                    }
                }

                .content {
                    > ul {
                        li {
                            &::before {
                                background-color: $phase5Color;
                            }
                        }
                    }

                    a {
                        color: $phase5Color;

                        &:hover {
                            color: $phase5Color;
                        }
                    }

                    .sub-step {
                        &::before {
                            background-color: $phase5Color;
                        }
                    }
                }
            }
        }
    }
}

// FIXME: Maybe the Czech versions of the phase labels should be different
// as well? Maybe we can also solve this in a nicer way (e.g., by moving the
// labels into a CSS variable.
html[lang="pl"] {
    .steps-timeline {
        .items {
            .item {
                &.phase-1 {
                    .title {
                        &::before {
                            content: 'Faza 1.';
                        }
                    }
                }

                &.phase-2 {
                    .title {
                        &::before {
                            content: 'Faza 2.';
                        }
                    }
                }

                &.phase-3 {
                    .title {
                        &::before {
                            content: 'Faza 3.';
                        }
                    }
                }

                &.phase-4 {
                    .title {
                        &::before {
                            content: 'Faza 4.';
                        }
                    }
                }

                &.phase-5 {
                    .title {
                        &::before {
                            content: 'Faza 5.';
                        }
                    }
                }
            }
        }
    }
}
