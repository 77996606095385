&__content {
    color: $gray;
    padding-bottom: 100px;

    p {
        margin: 30px 0 0;
    }

    hr {
        background: #ccc;
        border: 0;
        height: 1px;
        margin: 30px 0 0;
    }

    .wide-gutter {
        @include custom-gutter(80px);

        .custom-switch {
            height: 50px;
        }
    }

    .has-info-box {
        padding-right: 30px;
        position: relative;

        .dg-info-box {
            right: 0;
            top: -5px;
        }
    }

    &__form {
        .required-indicator {
            color: $secondary;
        }
    }

    &__form-field {
        margin-top: 60px;

        p {
            margin-bottom: 40px;
        }

        label {
            color: $default;
            hyphens: auto;
            margin-bottom: 0;
            position: relative;

            .dg-info-box {
                right: -25px;
                top: -5px;
            }
        }

        &--has-divider {
            border-top: 1px solid #ccc;
            margin-top: 30px;
            padding-top: 30px;
        }

        &--stacked {
            margin-top: 40px;

            label {
                margin-bottom: 20px;
            }
        }

        &__current-value {
            &--invalid {
                color: #ff1400;
            }
        }

        &__input {
            .dg-input-wrapper {
                input {
                    min-width: 0 !important;
                }
            }

            &--birthdate,
            &--expiration-date {
                .dg-input-wrapper {
                    input[type='text'] {
                        cursor: pointer;
                    }
                }
            }

            &--iban {
                .dg-input-wrapper {
                    > i {
                        color: #29bd07 !important;
                    }
                }
            }

            &--has-group-spacing {
                margin-top: 10px;
            }
        }
    }

    &__highlight {
        color: $dark;
        font-family: $headerFont;
        font-size: 18px;
        text-transform: uppercase;
    }

    &__form-sub-field {
        border-bottom: 1px solid #ccc;
        margin-top: 30px;
        padding-bottom: 30px;

        label {
            margin-bottom: 0;
        }

        .user-data__content__form-field__input {
            margin-top: 20px;
        }
    }

    &__form-actions {
        display: flex;
        gap: 80px;
        justify-content: center;
        margin-top: 100px;

        &--small {
            justify-content: flex-start;
            margin-top: 60px;
        }
    }

    &__iban-list {
        border-top: 1px solid #ccc;
        margin-top: 30px;
        padding-top: 30px;

        &__title {
            color: $dark;
            font-family: $headerFont;
            font-size: 18px;
            text-transform: uppercase;
        }

        &__table {
            margin-top: 30px;

            table {
                table-layout: fixed;
                width: 100%;

                tr {
                    th,
                    td {
                        padding-top: 10px;
                    }

                    th {
                        color: $default;
                    }

                    &:first-child {
                        th,
                        td {
                            padding-top: 0;
                        }
                    }
                }
            }
        }
    }

    &__loss-bearing-capacity-calculator {
        margin-top: 60px;

        &__title {
            font-size: 18px;
            font-weight: 400;
        }

        &__frame {
            background-color: $almostWhite;
            padding-top: 30px;
        }

        .user-data__content__form-field {
            margin-top: 20px;
            padding: 0 50px;

            &:first-child {
                margin-top: 0;
            }

            &--liquid-funds,
            &--yearly-expenses,
            &--net-capital {
                .dg-input-wrapper {
                    &::before {
                        font-size: 200%;
                        font-weight: 300;
                        left: 0;
                        line-height: 50px;
                        position: absolute;
                        text-align: center;
                        top: 0;
                        width: 40px;
                    }
                }
            }

            &--liquid-funds {
                .dg-input-wrapper {
                    &::before {
                        content: '+';
                    }
                }
            }

            &--yearly-expenses {
                .dg-input-wrapper {
                    &::before {
                        content: '-';
                    }
                }
            }

            &--net-capital {
                padding-top: 20px;
                position: relative;

                &::before,
                &::after {
                    background-color: #d9d9d9;
                    content: '';
                    display: block;
                    height: 1px;
                    left: 50px;
                    pointer-events: none;
                    position: absolute;
                    right: 50px;
                }

                &::before {
                    top: 0;
                }

                &::after {
                    top: 2px;
                }

                .dg-input-wrapper {
                    &::before {
                        content: '=';
                    }
                }
            }
        }

        .dg-input-wrapper {
            padding-left: 40px;
        }

        &__result {
            background-color: $primary;
            color: $white;
            margin-top: 20px;
            padding: 20px 50px;
        }

        &__result-label {
            font-size: 16px;
            font-weight: 500;
        }

        &__result-value {
            font-size: 28px;
            margin-left: auto;
        }
    }
}
