#titleLogoRisk {
    img {
        margin-top: 25px;
        max-width: 100%;
    }

    .riskContainer {
        background-color: $almostWhite;
        color: $lessDark;
        padding: 25px 15px;
        font-size: 16px;

        strong {
            font-size: 19px;
            font-weight: bold;
        }

        a {
            text-decoration: underline;
            font-weight: bold;
            color: inherit;
        }
    }

    .buttonContainer {
        padding-top: 20px;
        margin-top: 30px;
    }

    @include media-breakpoint-down(sm) {
        .riskContainer {
            font-size: 14px;

            strong {
                font-size: 16px;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        margin-top: 10px;

        img {
            width: 100%;
            height: auto;
        }

        .riskContainer {
            font-size: 13px;

            strong {
                font-size: 14px;
            }
        }

        .buttonContainer {
            padding-top: 0px;
        }
    }
}
