// Variables
@import 'variables';

// Mixins
@import 'mixins';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Other dependencies
@import '~tom-select/dist/scss/tom-select.bootstrap4';
@import '~vanilla-cookieconsent/src/cookieconsent.css';
@import '~butteruptoasts/src/butterup.css';

// Fonts
@import 'fonts/opensans';
@import 'fonts/exo2';
@import 'fonts/titilliumweb';
@import 'fonts/dagobertinvest';

// Layout
@import "layouts/header"; // in critical.css
@import "layouts/subheader";
@import "layouts/subheaderAlt";
@import "layouts/regheader";
@import "layouts/footer";
@import "layouts/contact";
@import "layouts/languageSelector";
@import "layouts/login";
@import "layouts/registration";
@import "layouts/newsletter";
@import "layouts/registrationSuccess";
@import "layouts/blogheader";

// Elements
@import "elements/colorUtil";
@import "elements/buttons";
@import "elements/statisticsCounter";
@import "elements/counter";
@import "elements/project";
@import "elements/singleProject";
@import "elements/singleClosedProject";
@import "elements/breadcrumbs";
@import "elements/headers";
@import "elements/contactForm";
@import "elements/legalComplaintForm";
@import "elements/popover";
@import "elements/risk";
@import "elements/child";
@import "elements/spinner";
@import "elements/partners";
@import "elements/testimonials";
@import "elements/overviewCards";
@import "elements/featureCards";
@import "elements/stepsPath";
@import "elements/stepsTimeline";
@import "elements/table";
@import "elements/titleWithIcon";
@import "elements/videoPreview";
@import "elements/responsiveVideo";
@import "elements/dgInputElements";

// VUE elements styles
@import "elements/vue/portfolioChart";
@import "elements/vue/milestones";
@import "elements/vue/financialStructure";
@import "elements/vue/rangeSelector";
@import "elements/vue/projectProgress";
@import "elements/vue/spinner";
@import "elements/vue/newsletterRegistrationForm";
@import "elements/vue/shareCalculator";

// Demo styles
@import "demo";

// Auth
@import "auth/auth";
@import "auth/ichapp";

// Pages
@import "pages/home";
@import "pages/project";
@import "pages/projects";
@import "pages/subpage";
@import "pages/blog";
@import "pages/closed-projects";
@import "pages/complaints";

//User
@import "user/user";
@import "userData/userData"; // New user views 2023

// Other
@import "other/global";
