#loginContainer {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 0vh;
    background: $lightBlue;
    z-index: 10001;
    color: white;
    font-family: $font-primary;
    font-size: 16px;
    overflow: hidden;
    transition: height 0.3s;

    .passwordStrength {
        .strength {
            display: inline-block;
            width: calc(33% - 6px);
            background-color: white;
            margin: 0 3px;
            height: 5px;

            &.green {
                background-color: $green;
            }
        }

        .passRules {
            font-size: 12px;
        }
    }

    .text {
        font-size: 16px;
    }

    &.show {
        height: 100vh;
    }

    .closeIcon {
        cursor: pointer;
        position: absolute;
        display: block;
        width: 70px;
        height: 70px;
        text-align: center;
        line-height: 1;
        font-size: 30px;
        right: 0;
        top: 110px;
        z-index: 10;
        background: $lightest;
        color: black;
        padding-top: 22px;
    }

    .watermarkIcon  {
        position: absolute;
        display: block;
        width: 155px;
        height: 170px;
        text-align: center;
        line-height: 1;
        font-size: 153px;
        left: 0;
        top: 110px;
        z-index: 10;
        color: $ultraLightBlue;
    }

    .title {
        font-family: $headerFont;
        margin-top: 97px;
        margin-bottom: 150px;
        font-size: 66px;
        line-height: 1.2;
        text-transform: uppercase;
    }

    .containerName {
        text-transform: uppercase;
        font-size: 30px;
        font-family: $headerFont;
        font-weight: 300;
    }

    .loginContainer {
        display: inline-block;
        width: 420px;
        padding: 45px;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .input-group-prepend {
        .input-group-text {
            font-size: 15px;
            background-color: $yellow;
        }
    }

    .invalid-feedback {
        display: block;
    }

    .forgot {
        display: block;
        color: white;
        margin-top: 30px;

        &::before {
            content: '\e002';
            font-family: $dagobertFont;
            font-size: 15px;
            margin-right: 20px;
            display: inline;
        }

        &:hover {
            text-decoration: none;
        }
    }

    @include media-breakpoint-down(lg) {
        .closeIcon {
            top: 65px;
        }

        .watermarkIcon  {
            width: 140px;
            height: 154px;
            font-size: 138px;
            top: 90px;
        }

        .title {
            margin-top: 80px;
            margin-bottom: 110px;
            font-size: 54px;
        }
    }

    @include media-breakpoint-down(md) {
        font-size: 18px;

        .closeIcon {
            top: 50px;
        }

        .watermarkIcon  {
            width: 110px;
            height: 120px;
            font-size: 108px;
            top: 70px;
        }

        .title {
            margin-top: 60px;
            margin-bottom: 110px;
            font-size: 52px;
        }
    }

    @include media-breakpoint-down(sm) {
        .title {
            margin-bottom: 0px;
        }

        .loginContainer {
            width: 290px;
            padding: 15px;
        }
    }

    @include media-breakpoint-down(xs) {
        font-size: 16px;

        .closeIcon {
            width: 55px;
            height: 55px;
            font-size: 23px;
            top: 30px;
            padding-top: 16px;
        }

        .watermarkIcon  {
            display: none;
        }

        .title {
            margin-top: 27px;
            font-size: 30px;
        }
        .forgot {
            margin-top: 10px;
        }
    }
}

// New login view (2023)

.login-content {
    color: $gray;
    padding-top: 75px;

    &__block {
        font-family: $mainFont;
        font-size: 15px;
        text-align: center;

        &__title {
            font-family: $navigationFont;
            font-size: 30px;
            font-weight: 300;
            line-height: 1;
            text-transform: uppercase;
        }

        &__description {
            margin-top: 15px;
        }

        &__forgot-password {
            margin-top: 40px;

            a {
                color: $primary;
                text-decoration: underline;
            }
        }

        &--login {
            .dg-input-wrapper {
                margin-top: 40px;

                &.dg-checkbox-input-wrapper {
                    text-align: left;
                }
            }

            .dg-input-error {
                margin-top: 40px;
            }

            .dg-button {
                margin-top: 50px;
            }
        }

        &--register {
            .dg-button {
                margin-top: 40px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 60px 0;

        .login-content__block {
            font-size: 13px;

            &__title {
                font-size: 24px;
            }

            &--login {
                .dg-input-wrapper,
                .dg-input-error {
                    margin-top: 20px;
                }

                .dg-button {
                    margin-top: 40px;
                }
            }

            &--register {
                margin-top: 60px;
            }
        }
    }
}

.login-overlay {
    background-color: white;
    color: $gray;
    height: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    transition: height 0.3s;
    width: 100%;
    z-index: 10001;

    .login-overlay-background {
        position: relative;
        min-height: 100vh;

        &::before {
            background-color: $almostWhite;
            content: '';
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 50%;
        }
    }

    &--show {
        height: 100vh;
    }

    &--showing {
        overflow: auto;

        &::before {
            position: fixed;
        }
    }

    &__header {
        padding: 40px (40px - calc($grid-gutter-width / 2)) 0;

        &__logo {
            padding-top: 60px;
            text-align: center;

            a {
                display: inline-block;

                img {
                    display: block;
                    max-width: 100%;
                    width: 350px;
                }
            }
        }

        &__close {
            text-align: right;

            button {
                @include input-reset;

                color: inherit;
                font-size: 24px;
                line-height: 1;
            }
        }
    }

    .login-content {
        padding-bottom: 100px;
    }

    @include media-breakpoint-down(sm) {
        .login-overlay-background::before {
            content: none;
        }

        &__header {
            background-color: $almostWhite;
            border-bottom: 1px solid $white;
            padding: 15px 0 10px;

            &__logo {
                padding-top: 0;

                a {
                    img {
                        width: 130px;
                    }
                }
            }

            &__close {
                button {
                    font-size: 12px;
                }
            }
        }

        .login-content {
            padding-top: 0;

            &__block {
                &--login {
                    background-color: $almostWhite;
                    padding-bottom: 60px;
                    padding-top: 30px;
                }

                &--register {
                    margin-top: 40px;
                }
            }
        }
    }
}
