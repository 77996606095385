$overlayPrimaryStart: $primary;
$overlayPrimaryEnd: $primary;
$overlaySecondaryStart: #cd9f14;
$overlaySecondaryEnd: #f7d481;
$overlayLightStart: #b8b8b8;
$overlayLightEnd: #d3d3d3;

#jumbo {
    position: relative;
    height: 560px;
    width: 100%;
    background-position: center center;
    background-size: cover;

    &.has-overlay {
        &::before {
            content: '';
            height: 100%;
            display: block;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        .overlay {
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;

            > .row {
                height: 100%;
            }

            &__project-type,
            &__description {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: (2 * $grid-gutter-width) 0;

                @include media-breakpoint-down(sm) {
                    padding-bottom: $grid-gutter-width;
                    padding-top: $grid-gutter-width;
                }
            }

            &__project-type {
                font-family: $headerFont;
                font-size: 4.5rem;
                justify-content: center;
                text-align: center;

                @include media-breakpoint-down(lg) {
                    font-size: 4rem;
                }

                @include media-breakpoint-down(md) {
                    font-size: 2.5rem;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 2rem;
                }

                small {
                    font-size: 50%;
                }
            }

            &__description {
                align-items: flex-end;
                font-family: $headerFont;
                font-size: 1.5rem;
                justify-content: flex-end;
                padding-right: 70 + $grid-gutter-width; // 70px is the width of the floating contact icon on the right

                @include media-breakpoint-down(lg) {
                    font-size: 1.25rem;
                }

                @include media-breakpoint-down(md) {
                    font-size: 1rem;
                }

                @include media-breakpoint-down(sm) {
                    align-items: center;
                }

                @include media-breakpoint-down(xs) {
                    padding-right: 0; //  We don't care about the floating contact icon on the right on small screens
                }

                &__inner {
                    position: relative;

                    &::after {
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                        bottom: -1em;
                        content: '';
                        display: block;
                        height: 3em;
                        opacity: 0.75;
                        position: absolute;
                        right: -2em;
                        width: 3em;
                        z-index: 1;

                        @include media-breakpoint-down(sm) {
                            right: -0.5em;
                        }
                    }
                }
            }
        }

        &--ecsp-pure {
            background-position: bottom center;

            .overlay {
                color: $white;
                text-shadow: 2px 2px 2px transparentize($black, 0.5);
            }
        }

        &--primary {
            &::before {
                background: linear-gradient(90deg, transparentize($overlayPrimaryStart, 0) 0%, transparentize($overlayPrimaryStart, 0) 34%, transparentize($overlayPrimaryEnd, 0.7) 100%);
            }

            .overlay {
                color: $white;

                &__description {
                    &__inner {
                        &::after {
                            background-image: url('/images/ecsp-checkmark-secondary.svg');
                        }
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                &::before {
                    background: transparentize($overlayPrimaryStart, 0.25);
                }
            }
        }

        &--secondary {
            &::before {
                background: linear-gradient(90deg, transparentize($overlaySecondaryStart, 0) 0%, transparentize($overlaySecondaryStart, 0) 34%, transparentize($overlaySecondaryEnd, 0.7) 100%);
            }

            .overlay {
                color: $dark;

                &__description {
                    &__inner {
                        &::after {
                            background-image: url('/images/ecsp-checkmark-primary.svg');
                        }
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                &::before {
                    background: transparentize($overlaySecondaryStart, 0.25);
                }
            }
        }

        &--light {
            &::before {
                background: linear-gradient(90deg, transparentize($overlayLightStart, 0) 0%, transparentize($overlayLightStart, 0) 34%, transparentize($overlayLightEnd, 0.7) 100%);
            }

            .overlay {
                color: $primary;

                &__description {
                    &__inner {
                        &::after {
                            background-image: url('/images/ecsp-checkmark-primary.svg');
                        }
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                &::before {
                    background: transparentize($overlayLightStart, 0.25);
                }
            }
        }
    }

    .tabsContainer {
        position: absolute;
        bottom: 0;
        z-index: 3;
    }

    @include media-breakpoint-down(lg) {
        height: 400px;
    }

    @include media-breakpoint-down(md) {
        height: 300px;
    }

    @include media-breakpoint-down(sm) {
        height: 450px;
    }
}
