.user-data {
    font-family: $mainFont;
    font-size: 15px;
    overflow-x: hidden; // To prevent the sidebar background from causing horizontal scrolling in some browsers

    @import 'elements/title';
    @import 'elements/subtitle';
    @import 'elements/message';

    @import 'sections/sidebar';
    @import 'sections/content'
}
