.feature-cards {
    @include section-spacing;

    &.no-spacing {
        padding-top: 0;
    }

    &.negate-container-spacing {
        margin-left: -(calc($grid-gutter-width / 2));
        margin-right: -(calc($grid-gutter-width / 2));
    }

    .items {
        @include media-breakpoint-down(sm) {
            margin-top: -10px;
        }

        .item {
            background-color: $primary;
            color: $white;
            min-height: 100%;
            padding: 30px 30px 15px;

            @include media-breakpoint-down(sm) {
                margin-top: 10px;
                min-height: 0;
            }

            @include media-breakpoint-down(xs) {
                padding: 30px;
            }

            .title {
                border-bottom: 1px solid;
                padding-bottom: 30px;

                @include media-breakpoint-down(xs) {
                    padding-bottom: 20px;
                }

                h2 {
                    color: inherit;
                    font-size: 26px;
                    font-weight: 400;
                    margin: 0;

                    @include media-breakpoint-down(xs) {
                        font-size: 20px;
                    }
                }
            }

            .features {
                margin-top: 30px;

                @include media-breakpoint-down(xs) {
                    margin-top: 20px;
                }

                dl {
                    align-items: baseline;
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0;

                    dt,
                    dd {
                        font-family: $mainFont;
                        margin: 1em 0 0;

                        &:first-of-type {
                            margin-top: 0;
                        }
                    }

                    dt {
                        font-size: 18px;
                        font-weight: 500;
                        padding-right: calc($grid-gutter-width / 2);
                        text-align: right;
                        width: 40%;

                        @include media-breakpoint-down(xs) {
                            font-size: 14px;
                        }
                    }

                    dd {
                        font-size: 16px;
                        padding-left: calc($grid-gutter-width / 2);
                        width: 60%;

                        @include media-breakpoint-down(xs) {
                            font-size: 13px;
                        }
                    }
                }
            }

            .footnote {
                color: $light;
                font-family: $mainFont;
                font-size: 13px;
                margin-top: 30px;

                @include media-breakpoint-down(xs) {
                    font-size: 12px;
                }
            }

            &.secondary {
                background-color: $secondary;
                color: $lessDark;

                .footnote {
                    color: $default;
                }
            }
        }
    }
}
