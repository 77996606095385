$marginBottomXs: 20px;
$titleFontSizeXs: 24px;
$titlePaddingBottomXs: 5px;
$titlePaddingTopXs: 20px;

$breadcrumbsMarginTopXs: 10px;
$breadcrumbsFontSizeXs: 12px;

#subheader {
    position: relative;
    background-color: $yellow;
    height: 335px;
    margin-bottom: 185px;

    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '\e005';
        font-family: $dagobertFont;
        color: $lessYellow;
        font-size: 170px;
        line-height: 1;
    }

    .title {
        padding-bottom: 120px;

        h1 {
            text-transform: uppercase;
            font-weight: 400;
            margin: 0px;
        }
    }

    img {
        max-width: 100%;
        margin-top: 45px;
        border: 15px white solid;
    }

    #breadcrumbs {
        font-family: $mainFont;
        position: absolute;
        top: 335px;
    }

    @include media-breakpoint-down(md) {
        height: 300px;

        &::before {
            font-size: 120px;
        }

        h1 {
            margin-top: 50px;
        }
    }

    @include media-breakpoint-down(sm) {
        height: 215px;

        &::before {
            font-size: 84px;
        }

        h1 {
            margin-top: 40px;
        }
    }

    @include media-breakpoint-down(xs) {
        background-color: transparent;
        height: auto;
        margin-bottom: $marginBottomXs;

        &::before {
            content: none;
        }

        .title {
            overflow-x: hidden;
            padding-top: $titlePaddingTopXs;
            padding-bottom: $titlePaddingBottomXs;
            position: relative;

            &::after {
                background-color: $secondary;
                bottom: 0;
                content: '';
                height: 2px;
                left: calc($grid-gutter-width / 2); // to align with the start of the column
                pointer-events: none;
                position: absolute;
                width: 999999px;
            }

            h1 {
                font-size: $titleFontSizeXs;
                font-weight: 300;
            }
        }

        #breadcrumbs {
            font-size: $breadcrumbsFontSizeXs;
            margin-top: 0px;
            position: relative;
            top: 0;

            @include media-breakpoint-down(xs) {
                margin-top: $breadcrumbsMarginTopXs;
            }
        }
    }
}
