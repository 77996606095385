.floating-invest-now-button {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 10;

    .dg-button {
        width: 100%;
    }
}
