.blueBlock {
    width: 100%;
    height: 100%;
    background-color: $blue;
    color: white;
    font-family: $font-alt;
    font-weight: 300;
    font-size: 24px;
    text-transform: uppercase;
    padding-top: 60px;
    padding-bottom: 60px;

    .value {
        font-size: 40px;
        margin-bottom: 60px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 16px;

        .value {
            font-size: 32px;
        }
    }

    @include media-breakpoint-down(xs) {
        padding-top: 30px;
        padding-bottom: 30px;

        .value {
            font-size: 32px;
            margin-bottom: 30px;
        }
    }
}