table {
    width: 100%;
    border-top: 1px solid $lighter;
    border-bottom: 1px solid $lighter;
    margin-bottom: 50px;

    tr {
        &:nth-child(odd) {
            background-color: $almostWhite;
        }

        td {
            padding-left: 30px;
            &:nth-child(odd) {
                font-weight: 700;
                color: $dark;
                vertical-align: top;
                padding-left: 15px;
                width: 260px;
            }

            @include media-breakpoint-down(xs) {
                display: block;
            }
        }
    }
}