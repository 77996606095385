#auth {
    font-family: $font-primary;
    font-size: 16px;
    padding-bottom: 100px;

    .passwordStrength {
        .strength {
            display: inline-block;
            width: calc(33% - 6px);
            background-color: white;
            margin: 0 3px;
            height: 5px;

            &.green {
                background-color: $green;
            }
        }

        .passRules {
            font-size: 12px;
        }
    }

    .extraText {
        font-family: $font-alt;
        font-size: 30px;
        font-weight: 300;
    }

    .containerName {
        text-transform: uppercase;
        font-size: 30px;
        font-family: $headerFont;
        font-weight: 300;
    }

    .loginContainer {
        display: inline-block;
        width: 420px;
        padding: 45px;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .regSteps {
        display: flex;
        width: 100%;
        height: 2px;
        background-color: $blue;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 72px;

        &::before {
            position: absolute;
            content: '';
            display: block;
            width: 0;
            height: 2px;
            left: 15px;
            top: 30px;
            background-color: $yellow;
        }

        &.step2 {
            &::before {
                width: calc(33.333% - 30px);
            }
        }

        &.step3 {
            &::before {
                width: calc(66.666% - 30px);
            }
        }

        &.step4 {
            &::before {
                width: calc(100% - 30px);
            }
        }

        .step {
            display: block;
            width: 45px;
            height: 45px;
            border: 2px solid $blue;
            border-radius: 50%;
            text-align: center;
            font-size: 20px;
            line-height: 41px;
            margin-top: -22px;
            background-color: #FFF;
            color: $blue;
            z-index: 1040;

            &.selected {
                width: 60px;
                height: 60px;
                line-height: 56px;
                margin-top: -30px;
                border: 2px solid $yellow;
                font-size: 32px;
            }

            &.done {
                border: 2px solid $yellow;

                &::before {
                    content: '✓';
                    color: $yellow;
                    font-size: 26px;
                }
            }
        }
    }

    .registerContainer {
        display: inline-block;
        width: 100%;
        max-width: 795px;
        padding: 15px 45px;
        background-color: rgba(0, 0, 0, 0.1);

        .row {
            position: relative;
        }

        .pepGroup {
            max-height: 0;
            overflow: hidden;

            &.show {
                max-height: 100%;
            }
        }

        .infoContainer {
            position: absolute;
            top: 10px;
            right: 15px;
            font-size: 25px;
            line-height: 1;
            z-index: 5;
            color: $blue;

            i {
                cursor: pointer;
            }
        }

        .btn-secondary {
            margin-bottom: -50px;
        }

        .pepSpecific {
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s linear;

            &.show {
                max-height: 150px;
            }
        }

        .pepSelected {
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s linear;

            &.show {
                max-height: 150px;
            }

            i {
                font-size: 14px;
            }
        }

        .hint {
            max-height: 0;
            overflow: hidden;
            font-size: 15px;
            border: 0;
            transition: all 0.75s linear;
            padding: 0px;
            margin: 0;

            &.show {
                max-height: 100%;
                padding: 10px;
                border: 1px solid black;
                margin-top: 10px;
            }
        }

        .custom-file-label {
            overflow: hidden;

            &::after {
                content: 'Durchsuche';
            }
        }
    }

    #firmaContainer {
        max-height: 0;
        transition: all 0.25s linear;
        overflow: hidden;

        &.show {
            max-height: 180px;
        }
    }

    .linksContainer {
        display: inline-block;
        width: 100%;
        max-width: 795px;
        padding: 0 45px;
        text-align: left;
    }

    .input-group-prepend {
        .input-group-text {
            font-size: 15px;
            background-color: $yellow;
        }
    }

    .forgot {
        display: block;
        color: $default;
        margin-top: 30px;

        &::before {
            content: '\e002';
            font-family: $dagobertFont;
            font-size: 15px;
            margin-right: 20px;
            display: inline;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .backLink {
        position: relative;
        display: block;
        color: $default;
        margin-top: 30px;
        padding-left: 30px;

        &::before {
            position: absolute;
            left: 0;
            content: '\e002';
            font-family: $dagobertFont;
            font-size: 15px;
            display: inline;
            transform: rotate(180deg);
        }

        &:hover {
            text-decoration: none;
        }
    }

    .invalid-feedback {
        display: block;
    }

    @include media-breakpoint-down(lg) {
        .title {
            margin-top: 80px;
            margin-bottom: 110px;
            font-size: 54px;
        }
    }

    @include media-breakpoint-down(md) {
        font-size: 18px;

        .title {
            margin-top: 60px;
            margin-bottom: 110px;
            font-size: 52px;
        }
    }

    @include media-breakpoint-down(sm) {
        .title {
            margin-bottom: 0px;
        }

        .loginContainer {
            width: 290px;
            padding: 15px;
        }
    }

    @include media-breakpoint-down(xs) {
        font-size: 16px;

        .registerContainer {
            padding: 15px 10px;
        }

        .forgot {
            margin-top: 10px;
        }
    }
}
