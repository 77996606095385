#demo {
    img {
        max-width: 100%;
    }
    
    table {
        border-collapse: collapse;
        border-spacing: 0px;

        tr {
            td {
                padding: 0 10px;
            }
            &:nth-child(3n) {
                border-bottom: 1px #000 solid;
            }
        }
    }
}