.table-wrap {
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: $almostWhite;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $lighter;
    }

    table {
        width: 100%;
        font-family: $navigationFont;
        font-size: 14px;
        color: $lessDark;

        tr {
            td {
                padding: 10px 5px;
                border-bottom: 1px $light solid;
                text-align: center;

                &:nth-child(1) {
                    text-align: left;
                }
            }
        }

        thead {
            tr {
                td {
                    font-size: 16px;
                    font-weight: 600;
                    vertical-align: bottom;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            font-size: 12px;

            thead {
                tr {
                    td {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.table-wrap-unstyled {
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: $almostWhite;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $lighter;
    }

    table {
        width: 100%;
    }
}
