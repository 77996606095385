#leftCol {
    border-right: 1px $light solid;
    height: 100%;
    
    .title {
        padding-left: 25px;
    }

    a {
        display: block;
        width: 100%;
        height: 40px;
        color: $lessDark;
        font-size: 14px;
        font-family: $navigationFont;
        line-height: 40px;
        padding-left: 25px;

        &.selected {
            background: $lightBlue;
            color: white;
        }

        &:hover {
            background: $lightBlue;
            color: white;
            text-decoration: none;
        }

        &.multi {
            &::after {
                display: inline-block;
                width: 10px;
                height: 40px;
                content: '\e013';
                font-family: $dagobertFont;
                font-size: 10px;
                margin-left: 10px;
            }
        }
    }

    .submenu {
        a {
            padding-left: 45px;
        }
    }
}