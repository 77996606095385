@mixin input-reset {
    appearance: none;
    background: none;
    border: 0;
    border-radius: 0;
    font-family: inherit;
    margin: 0;
    outline: 0;
    padding: 0;

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
}

@mixin dg-button-variant ($backgroundColor, $color, $visibleBorder: true, $invertColors: false) {
    @if $invertColors {
        background-color: $color;
        color: $backgroundColor;
    } @else {
        background-color: $backgroundColor;
        color: $color;
    }

    @if $visibleBorder {
        border: 1px solid $backgroundColor;
    } @else {
        border: 1px solid transparent;
    }

    &:disabled {
        &:not(.keep-visually-enabled) {
            opacity: 0.65;
        }
    }

    &:focus {
        &:not([disabled]) {
            @if $invertColors {
                background-color: $backgroundColor;
                color: $color;
            } @else {
                background-color: $color;
                color: $backgroundColor;
            }

            @if $visibleBorder {
                border-color: $backgroundColor;
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            &:not([disabled]) {
                @if $invertColors {
                    background-color: $backgroundColor;
                    color: $color;
                } @else {
                    background-color: $color;
                    color: $backgroundColor;
                }

                @if $visibleBorder {
                    border-color: $backgroundColor;
                }
            }
        }
    }
}

@mixin custom-gutter($gutter-width: $grid-gutter-width) {
    $half-gutter: calc($gutter-width / 2);

    margin-left: -$half-gutter;
    margin-right: -$half-gutter;

    > [class^='col'] {
        padding-left: $half-gutter;
        padding-right: $half-gutter;
    }
}

@mixin section-spacing($use-margin: false, $use-smaller-spacing: false) {
    $spacing: 140px;
    $spacingMd: 100px;
    $spacingXs: 80px;

    $smallerSpacing: 80px;
    $smallerSpacingMd: 60px;
    $smallerSpacingXs: 40px;

    @if $use-margin {
        @if $use-smaller-spacing {
            margin-top: $smallerSpacing;

            @include media-breakpoint-down(md) {
                margin-top: $smallerSpacingMd;
            }

            @include media-breakpoint-down(xs) {
                margin-top: $smallerSpacingXs;
            }
        }
        @else {
            margin-top: $spacing;

            @include media-breakpoint-down(md) {
                margin-top: $spacingMd;
            }

            @include media-breakpoint-down(xs) {
                margin-top: $spacingXs;
            }
        }
    }
    @else {
        @if $use-smaller-spacing {
            padding-top: $smallerSpacing;

            @include media-breakpoint-down(md) {
                padding-top: $smallerSpacingMd;
            }

            @include media-breakpoint-down(xs) {
                padding-top: $smallerSpacingXs;
            }
        }
        @else {
            padding-top: $spacing;

            @include media-breakpoint-down(md) {
                padding-top: $spacingMd;
            }

            @include media-breakpoint-down(xs) {
                padding-top: $spacingXs;
            }
        }
    }
}

@mixin section-spacing-bottom($use-margin: false, $use-smaller-spacing: false) {
    $spacing: 140px;
    $spacingMd: 100px;
    $spacingXs: 80px;

    $smallerSpacing: 80px;
    $smallerSpacingMd: 60px;
    $smallerSpacingXs: 40px;

    @if $use-margin {
        @if $use-smaller-spacing {
            margin-bottom: $smallerSpacing;

            @include media-breakpoint-down(md) {
                margin-bottom: $smallerSpacingMd;
            }

            @include media-breakpoint-down(xs) {
                margin-bottom: $smallerSpacingXs;
            }
        }
        @else {
            margin-bottom: $spacing;

            @include media-breakpoint-down(md) {
                margin-bottom: $spacingMd;
            }

            @include media-breakpoint-down(xs) {
                margin-bottom: $spacingXs;
            }
        }
    }
    @else {
        @if $use-smaller-spacing {
            padding-bottom: $smallerSpacing;

            @include media-breakpoint-down(md) {
                padding-bottom: $smallerSpacingMd;
            }

            @include media-breakpoint-down(xs) {
                padding-bottom: $smallerSpacingXs;
            }
        }
        @else {
            padding-bottom: $spacing;

            @include media-breakpoint-down(md) {
                padding-bottom: $spacingMd;
            }

            @include media-breakpoint-down(xs) {
                padding-bottom: $spacingXs;
            }
        }
    }
}

@mixin title-with-icon {
    $fontSize: 52px;
    $fontSizeMd: 46px;
    $fontSizeSm: 40px;
    $fontSizeXs: 24px;

    h1,
    h2 {
        display: inline-block;
        font-size: $fontSize;
        margin: 0;
        padding-bottom: 0.2em;
        padding-left: 2em;
        position: relative;
        text-transform: uppercase;

        &::before {
            background-size: auto 100%;
            background-image: url('../images/icons/logo-bars-zuruckbezahlt.svg');
            background-repeat: no-repeat;
            bottom: 0.4em; // to line up with the actual letters, adjusted for the font spacing
            content: '';
            display: block;
            height: 1.5em;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 1.5em;
            z-index: -1;
        }

        &::after {
            background-color: $secondary;
            bottom: 0;
            content: '';
            height: 2px;
            pointer-events: none;
            position: absolute;
            right: 0;
            width: 999999px;
        }

        @include media-breakpoint-down(md) {
            font-size: $fontSizeMd;
        }

        @include media-breakpoint-down(sm) {
            font-size: $fontSizeSm;
        }

        @include media-breakpoint-down(xs) {
            font-size: $fontSizeXs;
        }
    }
}

@mixin title-without-icon {
    $fontSize: 52px;
    $fontSizeMd: 46px;
    $fontSizeSm: 40px;
    $fontSizeXs: 24px;

    h1,
    h2 {
        display: inline-block;
        font-size: $fontSize;
        margin: 0;
        padding-bottom: 0.2em;
        position: relative;
        text-transform: uppercase;

        &::after {
            background-color: $secondary;
            bottom: 0;
            content: '';
            height: 2px;
            pointer-events: none;
            position: absolute;
            right: 0;
            width: 999999px;
        }

        @include media-breakpoint-down(md) {
            font-size: $fontSizeMd;
        }

        @include media-breakpoint-down(sm) {
            font-size: $fontSizeSm;
        }

        @include media-breakpoint-down(xs) {
            font-size: $fontSizeXs;
        }
    }
}

@mixin frontpage-action {
    $marginTop: 110px;
    $marginTopLg: 90px;
    $marginTopXs: 50px;

    margin-top: $marginTop;
    text-align: center;

    @include media-breakpoint-down(lg) {
        margin-top: $marginTopLg;
    }

    @include media-breakpoint-down(xs) {
        margin-top: $marginTopXs;
    }
}
