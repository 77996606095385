$titleFontSize: 44px;
$titleFontSizeMd: 40px;
$titleFonSizeSm: 34px;
$titleFonSizeXs: 25px;

$descriptionBodyBarHeight: 10px;
$descriptionBodyBarWidth: 100px;
$descriptionBodyFontSize: 20px;
$descriptionBodyFontSizeMd: 18px;
$descriptionBodyFontSizeSm: 16px;
$descriptionBodyFontSizeXs: 14px;
$descriptionBodySpacing: 60px;
$descriptionBodySpacingMd: 50px;
$descriptionBodySpacingSm: 40px;
$descriptionBodySpacingXs: 20px;

$formMarginXs: 30px;
$formPadding: 75px;
$formPaddingMd: 70px 50px;
$formPaddingSm: 40px 30px;
$formPaddingXs: 30px;

.newsletter {
    @include section-spacing;

    .description {
        .title {
            h2 {
                color: $primary;
                font-family: $mainFont;
                font-size: $titleFontSize;
                margin: 0;
                text-transform: none;

                @include media-breakpoint-down(md) {
                    font-size: $titleFontSizeMd;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $titleFontSizeSm;
                }

                @include media-breakpoint-down(xs) {
                    font-size: $titleFontSizeXs;
                }
            }
        }

        .body {
            font-family: $mainFont;
            font-size: $descriptionBodyFontSize;
            font-weight: 300;
            margin-top: $descriptionBodySpacing;
            padding-top: $descriptionBodySpacing + $descriptionBodyBarHeight;
            position: relative;

            &::before {
                background-color: $secondary;
                content: '';
                display: block;
                height: $descriptionBodyBarHeight;
                left: 0;
                pointer-events: none;
                position: absolute;
                top: 0;
                width: $descriptionBodyBarWidth;
            }

            @include media-breakpoint-down(md) {
                font-size: $descriptionBodyFontSizeMd;
                margin-top: $descriptionBodySpacingMd;
                padding-top: $descriptionBodySpacingMd + $descriptionBodyBarHeight;
            }

            @include media-breakpoint-down(sm) {
                font-size: $descriptionBodyFontSizeSm;
                margin-top: $descriptionBodySpacingSm;
                padding-top: $descriptionBodySpacingSm + $descriptionBodyBarHeight;
            }

            @include media-breakpoint-down(xs) {
                font-size: $descriptionBodyFontSizeXs;
                margin-top: $descriptionBodySpacingXs;
                padding-top: 0;

                &::before {
                    content: none;
                }
            }

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .form {
        border: 1px solid $secondary;
        min-height: 100%;
        padding: $formPadding;

        @include media-breakpoint-down(md) {
            padding: $formPaddingMd;
        }

        @include media-breakpoint-down(sm) {
            padding: $formPaddingSm;
        }

        @include media-breakpoint-down(xs) {
            margin-top: $formMarginXs;
            min-height: 0;
            padding: $formPaddingXs;
        }
    }
}
