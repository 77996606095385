$overviewCardsPadding: 70px 0 110px;
$overviewCardsPaddingSm: 60px 0 90px;
$overviewCardsPaddingXs: 30px 0 75px;

$titleFontSize: 52px;
$titleFontSizeMd: 46px;
$titleFontSizeSm: 40px;
$titleFontSizeXs: 24px;

$itemsMarginTopSm: 40px;
$itemsMarginTopXs: 10px;

$itemMarginTop: 90px;
$itemMarginTopSm: $grid-gutter-width;
$itemPadding: 30px 60px 40px;
$itemPaddingXs: 30px 20px 40px;

$itemDescriptionBeforeWidth: 60px;
$itemDescriptionBeforeWidthXs: 30px;
$itemDescriptionFontSize: 15px;
$itemDescriptionFontSizeXs: 11px;
$itemDescriptionMarginTop: 30px;
$itemDescriptionMarginTopXs: 20px;
$itemDescriptionPaddingTop: 20px;
$itemIconFontSize: 50px;
$itemIconFontSizeXs: 30px;
$itemTitleFontSize: 20px;
$itemTitleFontSizeXs: 14px;
$itemTitleMarginTop: 20px;
$itemTitleMarginTopXs: 10px;

.overview-cards {
    background-color: $almostWhite;
    padding: $overviewCardsPadding;
    position: relative;

    @include media-breakpoint-down(sm) {
        padding: $overviewCardsPaddingSm;
    }

    @include media-breakpoint-down(xs) {
        padding: $overviewCardsPaddingXs;
    }

    &.add-bottom-spacing {
        @include section-spacing-bottom(true, false);
    }

    &.negate-container-spacing {
        margin-left: -(calc($grid-gutter-width / 2));
        margin-right: -(calc($grid-gutter-width / 2));
    }

    &.use-wide-gutter {
        .items {
            > .row {
                @include custom-gutter($itemMarginTop);
            }
        }
    }

    .title {
        text-align: center;

        h2 {
            color: $primary;
            font-size: $titleFontSize;
            font-weight: 600;
            margin: 0;
            text-transform: none;

            @include media-breakpoint-down(md) {
                font-size: $titleFontSizeMd;
            }

            @include media-breakpoint-down(sm) {
                font-size: $titleFontSizeSm;
            }

            @include media-breakpoint-down(xs) {
                font-size: $titleFontSizeXs;
            }
        }
    }

    .items {
        @include media-breakpoint-down(sm) {
            margin-top: $itemsMarginTopSm;
        }

        @include media-breakpoint-down(xs) {
            margin-top: $itemsMarginTopXs;
        }

        .item {
            background-color: $white;
            box-shadow: 0 4px 10px 4px rgba($black, 0.05);
            margin-top: $itemMarginTop;
            min-height: calc(100% - #{$itemMarginTop});
            padding: $itemPadding;

            @include media-breakpoint-down(sm) {
                margin-top: $itemMarginTopSm;
                min-height: calc(100% - #{$itemMarginTopSm});
            }

            @include media-breakpoint-down(xs) {
                padding: $itemPaddingXs;
            }

            .icon {
                color: $secondary;
                font-size: $itemIconFontSize;
                line-height: 1;
                text-align: center;

                @include media-breakpoint-down(xs) {
                    font-size: $itemIconFontSizeXs;
                }
            }

            .title {
                margin-top: $itemTitleMarginTop;
                text-align: center;

                @include media-breakpoint-down(xs) {
                    margin-top: $itemTitleMarginTopXs;
                }

                h3 {
                    color: $darker;
                    font-family: $mainFont;
                    font-size: $itemTitleFontSize;
                    font-weight: 600;
                    margin: 0;

                    @include media-breakpoint-down(xs) {
                        font-size: $itemTitleFontSizeXs;
                    }
                }
            }

            .description {
                color: $gray;
                font-family: $mainFont;
                font-size: $itemDescriptionFontSize;
                margin-top: $itemDescriptionMarginTop;
                padding-top: $itemDescriptionPaddingTop;
                position: relative;

                @include media-breakpoint-down(xs) {
                    font-size: $itemDescriptionFontSizeXs;
                    margin-top: $itemDescriptionMarginTopXs;
                }

                &::before {
                    background-color: $secondary;
                    content: '';
                    display: block;
                    height: 2px;
                    left: 50%;
                    pointer-events: none;
                    position: absolute;
                    top: -1px;
                    transform: translateX(-50%);
                    width: $itemDescriptionBeforeWidth;

                    @include media-breakpoint-down(xs) {
                        width: $itemDescriptionBeforeWidthXs;
                    }
                }

                p,
                ol,
                ul {
                    margin-bottom: 1rem !important;

                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                }

                ol,
                ul {
                    padding-left: 2em;

                    ol,
                    ul {
                        margin-bottom: 0 !important;
                    }
                }

                ol {
                    list-style: decimal outside;
                }

                ul {
                    list-style: disc outside;
                }

                b,
                strong {
                    color: $lessDark;
                }
            }
        }
    }

    .action {
        bottom: 0;
        left: 0;
        padding: 0 calc($grid-gutter-width / 2);
        position: absolute;
        text-align: center;
        transform: translateY(50%);
        width: 100%;
        z-index: 10;
    }
}
