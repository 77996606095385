#buyInvestment {
    table {
        width: 100%;
        font-family: $navigationFont;
        font-size: 14px;
        color: $lessDark;
        margin-top: 20px;
    
        tr {
            td {
                padding: 10px 10px;
                border-bottom: 1px $light solid;
                text-align: center;
    
                &:nth-child(1) {
                    text-align: left;
                }
    
                a.sort {
                    position: relative;
                    color: $lessDark;
    
                    &::before {
                        position: absolute;
                        right: -13px;
                        top: 0;
                        content: '\e002';
                        font-family: $dagobertFont;
                        display: block;
                        color: $light;
                        font-size: 12px;
                        line-height: 24px;
                        transform: rotate(90deg);
                        text-align: center;
                    }
    
                    &::after {
                        position: absolute;
                        right: -20px;
                        top: 0;
                        content: '\e002';
                        font-family: $dagobertFont;
                        display: block;
                        color: $light;
                        font-size: 12px;
                        line-height: 24px;
                        transform: rotate(-90deg);
                        text-align: center;
                    }
    
                    &.ASC {
                        &::before {
                            color: $lessDark;
                        }
                    }
    
                    &.DESC {
                        &::after {
                            color: $lessDark;
                        }
                    }
                }
    
                &.subTable {
                    border: 0;
                    padding: 0;
                }
    
                &.details {
                    padding: 0;
                    border: 0;
    
                }
    
                .btn.small {
                    text-transform: capitalize;
                }
                
                .detail {
                    padding: 30px;
                    border: 1px $light solid;
                    border-top: 0;
                    font-size: 18px;
    
                    h3 {
                        border-bottom: 1px $light solid;
                        font-size: 26px;
                    }
    
                    a.document {
                        display: inline-block;
                        width: 100%;
                        color: $lessDark;
                        text-decoration: none;
                        text-align: left;
            
                        &::before {
                            content: '\e00b';
                            font-family: $dagobertFont;
                            font-size: 50px;
                            margin-right: 20px;
                        }
            
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    
        thead {
            tr {
                td {
                    font-size: 16px;
                    font-weight: 600;
                    vertical-align: bottom;
                }
            }
        }
    
        @include media-breakpoint-down(sm) {
            font-size: 12px;
    
            thead {
                tr {
                    td {
                        font-size: 14px;
                    }
                }
            }
        }
    
        @include media-breakpoint-down(xs) {
            tr {
                td {
                    .detail {
                        padding: 10px;
                        font-size: 14px;
    
                        h3 {
                            font-size: 18px;
                        }
    
                        a.document {
                            display: block;
                            width: unset;
                
                            &::before {
                                font-size: 20px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .singleProject {
        display: block;
        position: relative;
        font-family: $headerFont;
        border: 1px solid $lighter;
        background-color: white;
        padding: 30px 20px 20px 20px;
        color: $dark;
    
        .description {
            font-family: $navigationFont;
            margin-bottom: 25px;
        }
    
        .imageData {
            position: relative;
    
            .imageContainer {
                position: relative;
                background-position: left top;
                background-size: cover;
                height: 100%;
    
                img {
                    width: 100%;
                    height: auto;
                }
            }
    
            table {
                width: 100%;
                color: black;
                line-height: 1;
                font-size: 15px;
                border: 0;
                margin-top: 0px;
                
                tr {
                    border: 0;
                    td {
                        padding-bottom: 5px;
                        border: 0;
    
                        &:nth-child(2) {
                            text-align: right;
                        }
                    }
                }

                img {
                    height: 18px;
                    float: right;
                }
            }
        }
    }

    #buyInvestmentOverlay {
        position: fixed;
        left: 0;
        top: 0px;
        width: 100%;
        height: 100vh;
        background: $lightBlue;
        z-index: 11;
        overflow: hidden;
        transition: height 0.3s;

        .investmentContainer {
            width: 100%;
            height: 100vh;
            overflow: auto;
            padding: 30px 200px;

            .headerContainer {
                position: relative;
                background: $yellow;
                height: 40px;
                color: $lessDark;
        
                .closeIcon {
                    cursor: pointer;
                    position: absolute;
                    display: block;
                    width: 20px;
                    height: 20px;
                    line-height: 1;
                    font-size: 20px;
                    right: 10px;
                    top: 10px;
                    z-index: 12;
                }
            }

            .bodyContainer {
                background: white;
                padding: 0 0 30px 0;

                #jumbo {
                    position: relative;
                    height: 560px;
                    width: 100%;
                    background-position: center center;
                    background-size: cover;
                
                    .tabsContainer {
                        position: absolute;
                        bottom: 0;
                    }
                
                    @include media-breakpoint-down(lg) {
                        height: 400px;
                    }
                    @include media-breakpoint-down(md) {
                        height: 300px;
                    }
                }

                .newsContainer {
                    margin-bottom: 50px;
                    padding: 15px;;
                    border: $lightest solid 1px;
                    .image {
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                table {
                    width: 100%;
                    font-family: $navigationFont;
                    font-size: 1rem;

                    tr {
                        border-bottom: 1px $lighter solid;

                        &:first-child {
                            border-top: 1px $lighter solid;
                        }

                        td {
                            height: 55px;
                            padding-left: 15px;
                            text-align: left;

                            &:first-child {
                                font-weight: 600;
                            }
                        }
                    }
                }

                .invalid-feedback {
                    display: block;
                }
            }
        }

        @include media-breakpoint-down(md) {
            .investmentContainer {
                padding: 50px 50px;

                .bodyContainer {
        
                    .projectName {
                        margin: 40px 0;
                        font-size: 28px;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .investmentContainer {
                padding: 20px 30px;

                .headerContainer {
                    height: 25px;
            
                    .closeIcon {
                        width: 15px;
                        height: 15px;
                        font-size: 15px;
                        right: 5px;
                        top: 5px;
                    }
                }
            }
        }

        @include media-breakpoint-down(xs) {
            font-size: 14px;

            .investmentContainer {
                padding: 0;
            }
        }
    }
}