h1{
    font-family: $headerFont;
    font-weight: 300;
    color: $dark;
    font-size: 50px;
    margin-bottom: 60px;

    @include media-breakpoint-down(md) {
        font-size: 44px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 40px;
        margin-bottom: 45px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 28px;
        margin-bottom: 30px;
    }
}

h2 {
    font-family: $headerFont;
    color: $dark;
    font-size: 48px;
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 70px;
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
        font-size: 44px;
    }
    
    @include media-breakpoint-down(sm) {
        font-size: 42px;
        margin-top: 50px;
        margin-bottom: 35px;
    }
    
    @include media-breakpoint-down(xs) {
        font-size: 26px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
}

h3 {
    font-family: $headerFont;
    text-transform: uppercase;
    font-weight: 400;
    color: $dark;
    margin-bottom: 7px;
    font-size: 22px;

    @include media-breakpoint-down(sm) {
        font-size: 20x;
    }
    
    @include media-breakpoint-down(xs) {
        font-size: 18px;
    }
}
