a.singleProject {
    display: block;
    position: relative;
    font-family: $headerFont;
    border: 1px solid $lighter;
    background-color: white;
    padding: 30px 20px 20px 20px;
    color: $dark;

    &:hover {
        text-decoration: none;
        background-color: $almostWhite;
    }

    .badge {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 130px;
        height: 30px;
        line-height: 26px;
        font-family: $navigationFont;
        font-size: 15px;
        text-transform: uppercase;
        text-align: center;
        color: $almostWhite;
        border-radius: 0;
        font-weight: 400;

        &.green {
            background-color: $green;
        }

        &.blue {
            background-color: $mediumBlue;
        }
    }

    .titleLocation {
        height: 85px;
        overflow: hidden;

        .title {
            font-weight: 700;
            font-size: 30px;
            line-height: 1;
        }

        .location {
            font-family: $navigationFont;
            font-size: 14px;
        }
    }

    .description {
        overflow: hidden;
        height: 70px;
        font-family: $navigationFont;
        margin-bottom: 25px;
    }

    .about {
        border-top: 2px solid $lighter;
        border-bottom: 2px solid $lighter;
        padding: 15px 0;

        font-size: 26px;
        line-height: 1;
        margin-bottom: 25px;

        .subText {
            color: #707070;
            font-size: 20px;
        }
    }

    .imageData {
        position: relative;

        .imageContainer {
            position: relative;
            background-position: left top;
            background-size: cover;
            height: 100%;

            .new {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 95px; 
                height: 95px; 
                border-top: 95px solid $yellow;
                border-right: 95px solid transparent;
                z-index: 5;

                span {
                    position: absolute;
                    top: -85px;
                    left: 10px;
                    font-family: $navigationFont;
                    font-size: 20px;
                    
                }
            }

            .comingSoon {
                position: absolute;
                left: 45%;
                top: 10%; 
                display: block;
                width: 80%; 
                height: 40px;
                z-index: 5;
                background-color: $yellow;
                font-size: 20px;
                font-family: $navigationFont;
                font-weight: 700;
                line-height: 40px;
                text-align: center;
                border-radius: 10px;
                box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
                transform: rotate(-35deg) translate(-50%, -50%);
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        table {
            width: 100%;
            color: black;
            line-height: 1;
            font-size: 15px;
            
            tr {

                td {
                    padding-bottom: 10px;

                    &:nth-child(2) {
                        text-align: right;
                    }
                }
            }
        }
    }

    .countryInvestments {
        margin-top: 25px;
        border-top: 2px solid $lighter;
        padding-top: 15px;
        line-height: 1;
        color: black;

        table {
            width: 100%;

            tr {

                td {
                    height: 23px;

                    img {
                        height: 18px;
                    }

                    &:first-child {
                        width: 200px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .titleLocation {
            .title {
                font-size: 28px;
            }
        }

        .about {
            font-size: 20px;
            line-height: 1;

            .subText {
                color: $default;
                font-size: 14px;
            }
        }

        .countryInvestments {
            font-size: 14px;
            table {
    
                tr {
    
                    td {
    
                        &:first-child {
                            width: 180px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-only(sm) {
        .titleLocation {
            .title {
                font-size: 30px;
            }
        }

        .description {
            height: unset;
        }
    }

    @include media-breakpoint-down(xs) {
        .description {
            height: unset;
            margin-bottom: 15px;
        }

        .about { 
            .subText {
                display: inline;
                margin-left: 20px;
            }
        }

        .countryInvestments {
            font-size: 12px;
            table {
    
                tr {
    
                    td {
    
                        &:first-child {
                            width: 150px;
                        }
                    }
                }
            }
        }
    }
}