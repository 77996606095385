@import "project/invest";
#project {
    @import "project/jumbo";
    @import "project/titleLogoRisk";
    @import "project/news";
    @import "project/floatingInvestNowButton";

    p {
        padding: 0;
        margin: 0;
        margin-bottom: 0.7rem;
    }

    .video {
        margin-top: 40px;

        iframe {
            width: 100% !important;
        }
    }

    h2 {
        margin: 20px 0;
    }

    .riskFrameTitle {
        background: white !important;
    }

    .leftCol {
        .buttonContainer {
            margin: 20px 0;
        }

        @include media-breakpoint-down(sm) {
            .buttonContainer {
                margin-top: 75px;
            }
        }

        @include media-breakpoint-down(xs) {
            .buttonContainer {
                margin-top: 50px;
            }
        }

        @import "project/leftCol/mainDescription";
        @import "project/leftCol/mainImage";
        @import "project/leftCol/description";
        @import "project/leftCol/loginTeaser";
    }

    .rightCol {
        img {
            max-width: calc(100% - 120px);
        }

        .riskContainer {
            background-color: $almostWhite;
            color: $lessDark;
            padding: 25px 15px;
            font-size: 16px;

            strong {
                font-size: 19px;
                font-weight: bold;
            }

            a {
                text-decoration: underline;
                font-weight: bold;
                color: inherit;
            }

            @include media-breakpoint-down(sm) {
                font-size: 14px;

                strong {
                    font-size: 16px;
                }
            }

            @include media-breakpoint-down(xs) {
                font-size: 13px;

                strong {
                    font-size: 14px;
                }
            }
        }

        .buttonContainer {
            margin-top: 50px;
            margin-bottom: 70px;
        }

        .boxTitle {
            background-color: $lightest;
            color: $dark;
            font-family: $headerFont;
            font-size: 22px;
            line-height: 45px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 300;
            margin-bottom: 40px;
        }

        a.document {
            display: inline-block;
            width: 100%;
            color: $lessDark;
            text-decoration: none;
            text-align: left;

            &::before {
                content: '\e00b';
                font-family: $dagobertFont;
                font-size: 50px;
                margin-right: 20px;
            }

            &:hover {
                text-decoration: none;
            }
        }

        iframe,
        object {
            height: 617px;
        }

        a.mail {
            display: block;
            font-weight: 700;
            text-decoration: none;
            color: $default;
            line-height: 20px;
            margin-bottom: 10px;

            &::before {
                content: '\e00c';
                float: left;
                font-family: $dagobertFont;
                font-weight: 400;
                font-size: 20px;
                margin-right: 20px;
            }
        }

        a.phone {
            display: block;
            text-decoration: none;
            color: $default;
            line-height: 20px;

            &::before {
                content: '\e00d';
                float: left;
                font-family: $dagobertFont;
                font-size: 20px;
                margin-right: 20px;
            }
        }

        @include media-breakpoint-down(md) {
            font-size: 14px;

            iframe,
            object {
                height: 450px;
            }
        }

        @include media-breakpoint-down(sm) {
            .buttonContainer {
                text-align: center;
                margin-top: 70px;
                margin-bottom: 70px;
            }

            iframe,
            object {
                height: 716px;
            }
        }

        @include media-breakpoint-down(xs) {
            .buttonContainer {
                margin-top: 40px;
                margin-bottom: 40px;
            }

            iframe,
            object {
                height: 400px;
            }
        }

        @import "project/rightCol/overview";
    }

    #sumarry  {
        @import "project/summary/table";
    }

    .image-gallery-container {
        .carousel {
            &.flickity-enabled {
                height: auto !important; // This fixes the non-responsive default behavior of carousel galleries
            }
        }
    }

    .project-nav {
        @include media-breakpoint-down(sm) {
            .btn {
                text-align: center !important;
                width: 100% !important;

                &.lock {
                    &::after {
                        align-items: center;
                        display: flex;
                        height: 100%;
                        top: 0;
                    }
                }
            }
        }
    }
}
