#menu {
    .title {
        background-color: $yellow;
        height: 210px;
        font-family: $font-alt;
        font-size: 66px;
        color: $lessDark;
        text-transform: uppercase;
        line-height: 1;

        .subtitle {
            font-size: 30px;
            margin-top: 40px;
        }
    }

    @include media-breakpoint-down(lg) {
        .title {
            font-size: 54px;
    
            .subtitle {
                font-size: 28px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .title {
            font-size: 52px;
    
            .subtitle {
                font-size: 26px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .title {
            height: 190px;
            font-size: 36px;

            .subtitle {
                font-size: 20px;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .title {
            height: 130px;
            font-size: 28px;

            .subtitle {
                font-size: 16px;
                margin-top: 35px;
            }
        }
    }

    .navigation {
        margin-top: -20px;

        @include media-breakpoint-down(md) {
            margin-top: -40px;
        }

        @include media-breakpoint-down(xs) {
            margin-top: -30px;
        }
    }
}