#complaint-container {
    color: #868686;
    font-size: 15px;

    h2 {
        color: #005673;
        font-family: OpenSans;
        font-size: 22px;
        font-weight: 600;
        margin: 0 0 25px;
        text-transform: none;
    }

    p {
        color: #868686;
        margin-bottom: 25px;
        font-size: 15px;
    }

    .heading--dark {
        color: #3F3F3F;
        font-family: "Exo2";
        font-weight: 300;
        font-size: 32px;
    }

    .section-title {
        font-weight: 200;
        line-height: 40px;
        margin-bottom: 25px;
        text-transform: none;
        border-bottom: 1px solid $yellow;
    }
}
